<template>
  <div class="view">
    <div
      class="panel flex-column full-height"
    >
      <div class="page-header">
        <h1 class="flex-expand">
          Financials
        </h1>
        <NavFunctions
          class="nav-functions"
          @open-message-history-report="openMessageHistoryReport"
        />
      </div>
      <div class="grid-container">
        <div class="left-col">
          <!-- PAGE FILTERS -->
          <div class="filters-row">
            <MultiSelect
              v-model="selectedStatistics"
              class="category-combobox"
              :options="statistics"
              option-label="key"
              :max-selected-labels="3"
              selected-items-label="{0} Statistics Selected"
              placeholder="Select some Statistics"
            />
            <!-- 
              Call the getCategoryList function whenever the category is changed
             -->
            <Dropdown
              v-model="selectedCategory"
              class="category-combobox"
              :options="categories"
              placeholder="Select a Category"
              @change="getCategoryList"
            />
            <div class="data-box unselected-box">
              <p>
                <strong>{{ periodDescription }}</strong>
              </p>
            </div>
            <div
              class="data-box"
              :class="selectedPharmacies.length ? `selected-box` : `unselected-box`"
            >
              <p>
                <strong>{{ selectedPharmacies.length ? selectedPharmacies.length : 0 }} Pharmacies</strong>
              </p>
            </div>
            <!-- 
              Open the settings modal on click
             -->
            <i
              class="button pi-sliders-h pi"
              @click="openSettingsModal"
            ></i>
          </div>
          <!-- STATS SUMMARY -->
          <div
            class="overview-stats"
            :style="loadingStatsData ? `gap: 10px;` : ``"
          >
            <!-- SCRIPTS SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Unique Scripts
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.scripts"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.scripts.rate_of_change)"
                >
                  {{ pharmacyStatsData.scripts.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.scripts.rate_of_change) }}
                </p>
              </div>
              
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.scripts"
                class="stats-value"
              >
                {{ Intl.NumberFormat().format(pharmacyStatsData.scripts.total) }}
              </h3>
            </div>
            <Skeleton
              v-else
              class="stats-skeleton"
            />
            <!-- PATIENTS SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Patients
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.patients"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.patients.rate_of_change)"
                >
                  {{ pharmacyStatsData.patients.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.patients.rate_of_change) }}
                </p>
              </div>
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.patients"
                class="stats-value"
              >
                {{ Intl.NumberFormat().format(pharmacyStatsData.patients.total) }}
              </h3>
            </div>
            <Skeleton
              v-else
              class="stats-skeleton"
            />
            <!-- MALES SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Males
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.sex && pharmacyStatsData.patients.sex.male"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.patients.sex.male.rate_of_change)"
                >
                  {{ pharmacyStatsData.patients.sex.male.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.patients.sex.male.rate_of_change) }}
                </p>
              </div>
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.sex && pharmacyStatsData.patients.sex.male"
                class="stats-value"
              >
                {{ Intl.NumberFormat().format(pharmacyStatsData.patients.sex.male.total) }}
              </h3>
            </div>
            <Skeleton
              v-else
              class="stats-skeleton"
            />
            <!-- FEMALES SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Females
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.sex && pharmacyStatsData.patients.sex.female"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.patients.sex.female.rate_of_change)"
                >
                  {{ pharmacyStatsData.patients.sex.female.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.patients.sex.female.rate_of_change) }}
                </p>
              </div>
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.sex && pharmacyStatsData.patients.sex.female"
                class="stats-value"
              >
                {{ Intl.NumberFormat().format(pharmacyStatsData.patients.sex.female.total) }}
              </h3>
            </div>
            <Skeleton
              v-else
              class="stats-skeleton"
            />
            <!-- ADHERENCE PATIENTS SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Adherent Patients
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.adherence"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.patients.adherence.rate_of_change)"
                >
                  {{ pharmacyStatsData.patients.adherence.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.patients.adherence.rate_of_change) }}
                </p>
              </div>
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.adherence"
                class="stats-value"
              >
                {{ Intl.NumberFormat().format(pharmacyStatsData.patients.adherence.total) }}
              </h3>
            </div>
            <Skeleton
              v-else
              class="stats-skeleton"
            />
            <!-- NON-ADHERENCE PATIENTS SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Non-Adherent Patients
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.non_adherence"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.patients.non_adherence.rate_of_change)"
                >
                  {{ pharmacyStatsData.patients.non_adherence.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.patients.non_adherence.rate_of_change) }}
                </p>
              </div>
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.patients && pharmacyStatsData.patients.non_adherence"
                class="stats-value"
              >
                {{ Intl.NumberFormat().format(pharmacyStatsData.patients.non_adherence.total) }}
              </h3>
            </div>
            <Skeleton
              v-else
              class="stats-skeleton"
            />
            <!-- TOTAL REVENUE OPPORTUNITY SUMMARY -->
            <div
              v-if="!loadingStatsData"
              class="overview_card"
            >
              <div class="stats-top-row">
                <p class="stats-heading">
                  Total Revenue Opportunity
                </p>
                <p
                  v-if="pharmacyStatsData && pharmacyStatsData.total_adherence_opportunity_cost"
                  class="stats-percentage"
                  :class="getRateColor(pharmacyStatsData.total_adherence_opportunity_cost.rate_of_change)"
                >
                  {{ pharmacyStatsData.total_adherence_opportunity_cost.rate_of_change > 0 ? '+' : '' }}{{ unboundPercent(pharmacyStatsData.total_adherence_opportunity_cost.rate_of_change) }}
                </p>
              </div>
              <h3
                v-if="pharmacyStatsData && pharmacyStatsData.total_adherence_opportunity_cost"
                class="stats-value"
              >
                ${{ Intl.NumberFormat().format(pharmacyStatsData.total_adherence_opportunity_cost.total) }}
              </h3>
            </div>
          </div>
          <!-- ADHERENCE TABLE -->
          <!-- TODO: Update Table to use v-for on returned data -->
          <!-- EXPORT ROW -->
          <div class="export">
            <span class="key-container">
              <span class="key-heading">Adherence:</span>
              <span class="key-text">
                <div
                  class="key-icon"
                  style="background-color: green;"
                ></div>
                Above Average
              </span>
              <span class="key-text">
                <div
                  class="key-icon"
                  style="background-color: grey;"
                ></div>
                Normal
              </span>
              <span class="key-text">
                <div
                  class="key-icon"
                  style="background-color: red;"
                ></div>
                Below Average
              </span>
            </span>
            <img
              v-if="!exportingCSV"
              :src="exportIconSrc"
              class="small-icon button flex-right"
              alt="Export Icon"
              @click="exportCSV"
            >
            <ProgressSpinner
              v-else
              class="small-icon flex-right"
              stroke-width="8"
              animation-duration=".75"
              fill="var(--surface-ground)"
              aria-label="Loading"
            />
          </div>
          <!--New Data table with AG-GRID library-->
          <ag-grid-vue
            :row-height="50"
            :header-height="55"
            style="flex: 1; margin-bottom: 1em;"
            class="ag-theme-quartz"
            :row-data="rowData"
            :column-defs="subHeadersList"
            :get-row-style="getRowStyle"
            :overlay-loading-template="overlayLoadingTemplate"
            :overlay-no-rows-template="overlayNoRowsTemplate"
            @grid-ready="onGridReady"
          />
        </div>
        <div class="right-col">
          <!-- BEST & WORST 3 CATEGORIES -->
          <TabView @tab-change="$store.dispatch('getRefreshToken');">
            <!-- BEST 3 CATEGORIES -->
            <TabPanel header="Best">
              <DataTable
                :value="categoryListData.best_categories.categories"
                :loading="!categoryListLoaded"
                class="no-borders"
              >
                <template #empty>
                  No records found
                </template>
                <template #loading>
                  Loading records, please wait...
                </template>
                <Column
                  header="Subcategory"
                  field="category"
                />
                <Column header="Adherence from SR AVG">
                  <template #body="slotProps">
                    <span
                      class="adherence-span"
                    >
                      <img
                        :src="getRateIcon(slotProps.data.adherent_script_percent)"
                        class="extrasmall-icon"
                        alt="Rate Icon"
                      >
                      {{ unboundPercent(slotProps.data.adherent_script_percent) }}
                    </span>
                  </template>
                </Column>
                <Column header="Revenue">
                  <template #body="slotProps">
                    <span>
                      ${{ Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(slotProps.data.revenue_value) }}
                    </span>
                  </template>
                </Column>
                <!-- TOTALS FOOTER -->
                <ColumnGroup type="footer">
                  <Row>
                    <Column
                      footer="Total"
                      :colspan="2"
                    />
                    <Column>
                      <template #footer>
                        <span>
                          ${{ categoryListData.best_categories.total ? Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(categoryListData.best_categories.total) : 0 }}
                        </span>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </TabPanel>
            <!-- WORST 3 CATEGORIES -->
            <TabPanel header="Worst">
              <DataTable
                :value="categoryListData.worst_categories.categories"
                :loading="!categoryListLoaded"
                class="no-borders"
              >
                <template #empty>
                  No records found
                </template>
                <template #loading>
                  Loading records, please wait...
                </template>
                <Column
                  header="Subcategory"
                  field="category"
                />
                <Column header="Adherence from SR AVG">
                  <template #body="slotProps">
                    <span
                      class="adherence-span"
                    >
                      <img
                        :src="getRateIcon(slotProps.data.adherent_script_percent)"
                        class="extrasmall-icon"
                        alt="Rate Icon"
                      >
                      {{ unboundPercent(slotProps.data.adherent_script_percent) }}
                    </span>
                  </template>
                </Column>
                <Column header="Revenue">
                  <template #body="slotProps">
                    <span>
                      ${{ Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(slotProps.data.revenue_value) }}
                    </span>
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row>
                    <Column
                      footer="Total"
                      :colspan="2"
                    />
                    <Column>
                      <template #footer>
                        <span>
                          ${{ categoryListData.worst_categories.total ? Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(categoryListData.worst_categories.total) : 0 }}
                        </span>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </TabPanel>
          </TabView>
          <!-- OPPORTUNITY CALCULATOR -->
          <div class="flex-section light-blue-bg">
            <div class="flex-section-header">
              <h3 class="flex-expand blue-color">
                Opportunity Calculator
              </h3>
              <Button
                icon="pi pi-refresh"
                aria-label="Refresh"
                class="p-button-raised p-button-rounded"
                :disabled="!selectedSubcategory"
                @click="calculateOpportunity"
              />
            </div>
            <h4 class="blue-color">
              Subcategory
            </h4>
            <!-- SUBCATEGORY RADIO BUTTONS -->
            <div class="radio-group">
              <div
                :class="[subcategoryType == 'best' ? `selected-radio` : `unselected-radio`, `radio-item`]"
                @click="selectSubcategoryType('best')"
              >
                <div class="radio-circle"></div>
                <span class="radio-label">Best</span>
              </div>
              <div
                :class="[subcategoryType == 'worst' ? `selected-radio` : `unselected-radio`, `radio-item`]"
                @click="selectSubcategoryType('worst')"
              >
                <div class="radio-circle"></div>
                <span class="radio-label">Worst</span>
              </div>
              <div
                :class="[subcategoryType == 'manual' ? `selected-radio` : `unselected-radio`, `radio-item`]"
                @click="selectSubcategoryType('manual')"
              >
                <div class="radio-circle"></div>
                <span class="radio-label">Manual</span>
              </div>
            </div>
            <!-- SUBCATEGORY DROPDOWN -->
            <Dropdown
              v-model="selectedSubcategory"
              option-label="field"
              option-value="field"
              :options="categoryListData.columns"
              :placeholder="selectedSubcategory ? selectedSubcategory : 'Select a subcategory'"
              :disabled="subcategoryType != 'manual'"
            />
            <h4 class="blue-color">
              Adjust
            </h4>
            <!-- ADHERENCE ADJUSTMENT -->
            <div class="adjustment">
              <ToggleButton
                v-model="adherenceAdjustment"
                on-label="Adherence"
                off-label="Adherence"
              />
              <SelectButton
                v-model="adherenceDirection"
                :options="adjustmentDirections"
                option-label="sign"
                :allow-empty="false"
              />
              <span class="p-input-icon-left p-input-icon-right input-span">
                <i :class="[adherenceDirection.sign == '+' ? `pi pi-plus` : `pi pi-minus`]"></i>
                <InputText
                  v-model="adherenceAdjustmentValue"
                  type="number"
                  style="height: 40px;"
                />
                <i class="pi pi-percentage"></i>
              </span>
            </div>
            <!-- PRICE PER SCRIPT ADJUSTMENT -->
            <div class="adjustment">
              <ToggleButton
                v-model="pricePerScriptAdjustment"
                on-label="Price per Script"
                off-label="Price per Script"
              />
              <SelectButton
                v-model="pricePerScriptDirection"
                :options="adjustmentDirections"
                option-label="sign"
                :allow-empty="false"
              />
              <span class="p-input-icon-left p-input-icon-right input-span">
                <i :class="[pricePerScriptDirection.sign == '+' ? `pi pi-plus` : `pi pi-minus`]"></i>
                <InputText
                  v-model="pricePerScriptAdjustmentValue"
                  type="number"
                  style="height: 40px;"
                />
                <i class="pi pi-dollar"></i>
              </span>
            </div>
            <!-- OUTPUT CALCULATION -->
            <div class="output-calc">
              <!-- CURRENT -->
              <div class="opportunity-heading">
                <h4 class="blue-color">
                  Current
                </h4>
              </div>
              <div class="current-container">
                <!-- IF DATA IS LOADING OR NULL -->
                <div
                  v-if="loadingOpportunityData || currentOpportunityDataNull"
                  class="opportunity-flex"
                >
                  <span
                    v-if="loadingOpportunityData"
                    class="current-no-selection"
                  >
                    <ProgressSpinner
                      class="small-icon"
                      stroke-width="8"
                      animation-duration=".75"
                      fill="var(--surface-ground)"
                      aria-label="Loading"
                    />
                    Loading...
                  </span>
                  <p
                    v-else-if="currentOpportunityDataNull"
                    class="current-no-selection"
                  >
                    No Selection
                  </p>
                </div>
                <!-- IF DATA IS PRESENT -->
                <div
                  v-else
                  class="opportunity-grid"
                >
                  <!-- ADHERENCE -->
                  <div class="opportunity-data">
                    <h1 class="output-current-data">
                      {{ opportunityData.current_adherence == null ? '' : unboundPercent(opportunityData.current_adherence) }}
                    </h1>
                    <p class="output-current-label">
                      Adherence
                    </p>
                  </div>
                  <!-- SCRIPTS -->
                  <div class="opportunity-data">
                    <h1 class="output-current-data">
                      {{ opportunityData.total_script == null ? '' : Math.round(opportunityData.total_script) }}
                    </h1>
                    <p class="output-current-label">
                      Scripts
                    </p>
                  </div>
                  <!-- REVENUE -->
                  <div class="opportunity-data">
                    <h1
                      v-if="opportunityData.current_revenue"
                      class="output-current-data"
                    >
                      ${{ opportunityData.current_revenue ? Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(opportunityData.current_revenue) : '0' }}
                    </h1>
                    <p class="output-current-label">
                      Revenue
                    </p>
                  </div>
                </div>
              </div>
              <!-- POTENTIAL -->
              <div class="opportunity-heading">
                <h4 class="blue-color">
                  Potential
                </h4>
              </div>
              <div class="potential-container">
                <!-- IF DATA IS LOADING OR NULL -->
                <div
                  v-if="loadingOpportunityData || potentialOpportunityDataNull"
                  class="opportunity-flex"
                >
                  <span
                    v-if="loadingOpportunityData"
                    class="potential-no-selection"
                  >
                    <ProgressSpinner
                      class="small-icon"
                      stroke-width="8"
                      animation-duration=".75"
                      fill="var(--surface-ground)"
                      aria-label="Loading"
                    />
                    Loading...
                  </span>
                  <p
                    v-else-if="potentialOpportunityDataNull"
                    class="potential-no-selection"
                  >
                    No Selection
                  </p>
                </div>
                <!-- IF DATA IS PRESENT -->
                <div
                  v-else
                  class="opportunity-grid"
                >
                  <!-- ADHERENCE -->
                  <div class="opportunity-data">
                    <h1 class="output-potential-data">
                      {{ opportunityData.potential_adherence == null ? '' : unboundPercent(opportunityData.potential_adherence) }}
                    </h1>
                    <p class="output-potential-label">
                      Adherence
                    </p>
                  </div>
                  <!-- SCRIPTS -->
                  <div class="opportunity-data">
                    <h1 class="output-potential-data">
                      {{ opportunityData.potential_script == null ? '' : Math.round(opportunityData.potential_script) }}
                    </h1>
                    <p class="output-potential-label">
                      Scripts
                    </p>
                  </div>
                  <!-- REVENUE -->
                  <div class="opportunity-data">
                    <h1
                      v-if="opportunityData.potential_revenue"
                      class="output-potential-data"
                    >
                      ${{ opportunityData.potential_revenue ? Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(opportunityData.potential_revenue) : '0' }}
                    </h1>
                    <p class="output-potential-label">
                      Revenue
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 
      Financial Settings Modal
     -->
    <FinancialSettingsModal
      v-if="displaySettingsModal"
      :categories="categories"
      :selected-category="selectedCategory"
      :pharmacies="pharmacies"
      :selected-pharmacies="selectedPharmacies"
      :months="months"
      :quarters="quarters"
      :years="years"
      :selected-period-type="selectedPeriodType"
      :selected-period="selectedPeriod"
      :state-options="states"
      :selected-state="selectedState"
      :selected-postcode="selectedPostcode"
      @close-modal="closeModal"
      @update-filters="updateFilters"
    />
    <!-- 
      Message History Report Modal
     -->
    <MessageHistoryReport
      v-if="displayMessageHistoryReportModal"
      :pharmacies="pharmacies"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue'
import FinancialSettingsModal from '@/components/modals/financial-settings-modal.vue'
import MessageHistoryReport from '@/components/modals/message-history-report-modal.vue'
import axios from 'axios';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue3"; // Vue Grid Logic

export default {
  components: {
    NavFunctions,
    FinancialSettingsModal,
    MessageHistoryReport,
    AgGridVue
  },
  setup() {
    // Initialize empty list to populate row data and column definitions to be used for AG-GRID data table
    return {
      columnDefs : [{field:"pharmacy_name"}], 
      rowData : [],
    }
  },
  data() {
    return {
        displaySettingsModal: false,
        displayMessageHistoryReportModal: false,
        // SVG Image Imports
        exportIconSrc: require('@/assets/icons/export_icon.svg'),
        increasingArrowIconSrc: require('@/assets/icons/increasing_arrow_icon.svg'),
        decreasingArrowIconSrc: require('@/assets/icons/decreasing_arrow_icon.svg'),
        neutralLineIconSrc: require('@/assets/icons/neutral_line_icon.svg'),
        // Filters
        selectedCategory: '',
        categories: [],
        selectedPeriod: {
          year: new Date().getFullYear(),
          month: null,
          quarter: null
        },
        months: [
          {
            name: 'January',
            value: 1
          },
          {
            name: 'February',
            value: 2
          },
          {
            name: 'March',
            value: 3
          },
          {
            name: 'April',
            value: 4
          },
          {
            name: 'May',
            value: 5
          },
          {
            name: 'June',
            value: 6
          },
          {
            name: 'July',
            value: 7
          },
          {
            name: 'August',
            value: 8
          },
          {
            name: 'September',
            value: 9
          },
          {
            name: 'October',
            value: 10
          },
          {
            name: 'November',
            value: 11
          },
          {
            name: 'December',
            value: 12
          }
        ],
        quarters: [
          {
            name: 'Q1',
            value: 1
          },
          {
            name: 'Q2',
            value: 2
          },
          {
            name: 'Q3',
            value: 3
          },
          {
            name: 'Q4',
            value: 4
          }
        ],
        statistics: [
          {
            "key": "Revenue",
            "values": [
              "revenue_value"
            ]
          },
          {
            "key": "Number of Scripts",
            "values": [
              "script_count"
            ]
          },
          {
            "key": "Adherence",
            "values": [
              "adherent_script_percent",
              "adherence_performance"
            ]
          },
          {
            "key": "Loyalty",
            "values": [
              "loyalty_script_percent",
              "loyalty_performance"
            ]
          }
        ],
        selectedStatistics: [
          {
            "key": "Revenue",
            "values": [
              "revenue_value"
            ]
          },
          {
            "key": "Number of Scripts",
            "values": [
              "script_count"
            ]
          },
          {
            "key": "Adherence",
            "values": [
              "adherent_script_percent",
              "adherence_performance"
            ]
          },
          {
            "key": "Loyalty",
            "values": [
              "loyalty_script_percent",
              "loyalty_performance"
            ]
          }
        ],
        years: [],
        selectedPeriodType: 'Financial Year',
        pharmacies: [],
        selectedPharmacies: [],
        pharmacyInfoError: '',
        pharmacyInfoLoaded: false,
        selectedState: '',
        selectedPostcode: '',
        pharmacyStatsData: {},
        pharmacyStatsError: '',
        loadingStatsData: false,
        categoryListData: {
          "columns": [],
          "baseline": {},
          "selected_average": {},
          "pharmacies": [],
          "best_categories": {
            "categories": [],
          },
          "worst_categories": {
            "categories": [],
          }
        },
        exportingCSV: false,
        exportCSVError: '',
        opportunityData: {},
        opportunityDataError: '',
        loadingOpportunityData: false,
        columnData: [],
        categoryListError: '',
        categoryListLoaded: false,
        subcategoryType: null,
        selectedSubcategory: '',
        adherenceAdjustment: false,
        pricePerScriptAdjustment: false,
        sortField: "pharmacy_name",
        sortOrder: 1, 
        adherenceDirection: {
          sign: '+',
          value: 1
        },
        pricePerScriptDirection: {
          sign: '+',
          value: 1
        },
        adjustmentDirections: [
          {
            sign: '-',
            value: -1
          },
          {
            sign: '+',
            value: 1
          }
        ],
        adherenceAdjustmentValue: null,
        pricePerScriptAdjustmentValue: null,
        gridApi: null, // Grid options for revenue table
        overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading records, please wait...</span>', // overlay loading text customised to be inline with other tables
        overlayNoRowsTemplate: '<span aria-live="polite" aria-atomic="true" style="padding: 10px;">No data available</span>', // overlay to display when no data is available
  }
},
  computed: {
    /**
     * Returns the payload for the pharmacy statistics request
     * @returns {Object} - The payload for the pharmacy statistics request
     */
    opportunityCalculatorPayload() {
      return {
        subcategory: this.selectedSubcategory,
        pharmacy_ids: this.selectedPharmacies.map(pharmacy => pharmacy.pharmacy_id),
        adherence_adjustment: this.adherenceAdjustmentValue * this.adherenceDirection.value,
        price_per_script_adjustment: this.pricePerScriptAdjustmentValue * this.pricePerScriptDirection.value,
        year: this.selectedPeriod.year,
        month: this.selectedPeriod.month,
        quarter: this.selectedPeriod.quarter
      }
    },
     /**
     * Returns the payload for the initial enterprise category and pharmacy information request with selected period
     * @returns {Object} - The payload for the enterprise initial info request
     */
     initInfoPayload() {
      return {
        year: this.selectedPeriod.year,
        month: this.selectedPeriod.month,
        quarter: this.selectedPeriod.quarter
      }
    },
    /**
     * Returns the payload for the category list request
     * @returns {Object} - The payload for the category list request
     */
    categoryListPayload() {
      return {
        category: this.selectedCategory ? this.selectedCategory : '',
        pharmacies: this.selectedPharmacies.map(pharmacy => pharmacy.pharmacy_id),
        year: this.selectedPeriod.year,
        month: this.selectedPeriod.month,
        quarter: this.selectedPeriod.quarter
      }
    },
    /**
     * Returns the payload for the pharmacy statistics request
     * @returns {Object} - The payload for the pharmacy statistics request
     */
    pharmacyStatsPayload() {
      return {
        pharmacy_ids: this.selectedPharmacies.map(pharmacy => pharmacy.pharmacy_id),
        year: this.selectedPeriod.year,
        month: this.selectedPeriod.month,
        quarter: this.selectedPeriod.quarter 
      }
    },
    /**
     * Construct an array of column definitions, and their respective fields
     * Each value is an object - containing properties each column in the revenue table
     * @returns {Array} - An array of columns and their respective column definitions
     */
    subHeadersList() {
      let columnData = [];
      // First column as pharmacy name pinned to left
      columnData.push({field:"pharmacy_name", headerName:"Pharmacy Name", pinned:'left', width:300, unSortIcon: true, sort: 'asc', cellStyle:{padding:'0.25rem 1rem', 'text-align':'left'}});
      // Loop through each of the columns (Repatriation, Concession...)
      this.categoryListData.columns.forEach((column) => {
        // Loop through each of the statistics (Revenue, Number of Scripts...)
        // For each category, new category column is required
        let categoryColumn = {headerName: column.field,children:[]}; 
        this.selectedStatistics.forEach((statistic) => {
          if (statistic.values) {
            // Loop through each of the stat values (adherent_script_percent, adherence_performance...)
            statistic.values.forEach((value) => {
              // Create an object for each stat and add this object as children of categoryColumn
              let columnValue= column.field.replaceAll(' ', '_') + '_' + value;
              let statsObject = {field:columnValue, headerName: value,  wrapHeaderText: true, unSortIcon: true, width:150, cellStyle:{padding:'0.25rem 1rem', 'border-width':'0 0 0 1px', 'border-color': '#e9ecef'}};

              if (value.includes('revenue')) {
                statsObject.valueGetter = function(params){
                  let revenueColName = column.field.replaceAll(' ', '_') + "_revenue_value";
                  return params.data[revenueColName];
                }
                statsObject.valueFormatter = (params) => { 
                  if (params.value === null) {
                    return "";
                  }
                  return "$" + Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(params.value);
                }
                statsObject.headerName = 'Revenue';
              }
              else if (value.includes('script_count')) {
                statsObject.headerName = 'Number of Scripts';
                statsObject.valueGetter = function(params) {
                  let revenueColName = column.field.replaceAll(' ', '_') + "_script_count";
                  return params.data[revenueColName];
                }
                statsObject.cellStyle = (params) => {
                  let style = {padding:'0.25rem 1rem'};
                  // If the object is from total, hide the borders as the cells will be empty
                  if (params.data['pharmacy_name'] !== "TOTAL") {
                    style['border-width'] = '0 0 0 1px';
                    style['border-color'] = '#e9ecef';
                  }
                  return style;
                }
                statsObject.colSpan = (params) => {
                  // If the object is from total span to 3 columns and these will be empty, else 1 
                  if (params.data['pharmacy_name'] === "TOTAL") {
                    return 3;
                  }
                  else {
                    return 1;
                  }
                };
              }
              else if (value.includes('adherent_script_percent')) {
                statsObject.headerName = 'Adherence';
                // Get the values of adherence performance and adherence percent and form a list
                statsObject.valueGetter = function(params) {
                  let adherencePerformanceColName = column.field.replaceAll(' ', '_') + "_adherence_performance";
                  let adherenceScriptPercentColName = column.field.replaceAll(' ', '_') + "_adherent_script_percent";
                  return [params.data[adherencePerformanceColName], params.data[adherenceScriptPercentColName]];
                }

                // Render the cell with  adherence performance and percent
                // If the adherent percent is null, render a blank cell
                // If the adherent performance is undefined, render the performance only
                statsObject.cellRenderer = (params) => {
                  if (params.value[1] === null || params.value[1] === undefined) {
                    return "";
                  }
                  
                  if (params.value[0] === undefined) {
                    return this.unboundPercent(params.value[1]);  
                  }
                  return "<img src=" + this.getRateIcon(params.value[0]) + " class='extrasmall-icon' alt='Rate Icon'/> " + this.unboundPercent(params.value[1]);
                }
              }
              else if (value.includes('loyalty_script_percent')) {
                statsObject.headerName = 'Loyalty';
                // Get the values of loyalty performance and loyalty percent and form a list
                statsObject.valueGetter = function(params) {
                  let loyaltyPerformanceColName = column.field.replaceAll(' ', '_') + "_loyalty_performance";
                  let loyaltyScriptPercentColName = column.field.replaceAll(' ', '_') + "_loyalty_script_percent";
                  return [params.data[loyaltyPerformanceColName], params.data[loyaltyScriptPercentColName]];
                }
                // Render the cell with  loyalty performance and percent
                // If the loyalty percent is null, render a blank cell
                // If the loyalty performance is undefined, render the performance only
                statsObject.cellRenderer = (params) => {
                  if (params.value[1] === null  || params.value[1] === undefined) {
                    return "";
                  }
                  if (params.value[0] === undefined) {
                    return this.unboundPercent(params.value[1]);  
                  }
                  return "<img src="+this.getRateIcon(params.value[0]) + " class='extrasmall-icon' alt='Rate Icon'/> " + this.unboundPercent(params.value[1]);
                }
              }
              // Adherent and loyatly performance are rendering them alongside percentage
              // Removing this condition adds seperate performance columns to the revenue table
              if (!value.includes("performance")) {
                categoryColumn['children'].push(statsObject);
              }
            }); 
          }
        });
        // Add created category columns to the list and return
        columnData.push(categoryColumn);
      });
      return columnData;
    },
    /**
     * Calculate the width of the main header
     */
    mainHeaderWidth() {
      let length = this.selectedStatistics.length * 150
      return Math.max(length, 250) + 'px';
    },
    /**
     * Checks if Current Opporunity Data null
     * @returns {Boolean} - True if Current Opportunity Data is null
     */
    currentOpportunityDataNull() {
      return this.opportunityData.current_adherence == null
        && this.opportunityData.total_script == null
        && this.opportunityData.current_revenue == null;
    },
    /**
     * Checks if Potential Opporunity Data null
     * @returns {Boolean} - True if Potential Opportunity Data is null
     */
    potentialOpportunityDataNull() {
      return this.opportunityData.potential_adherence == null
        && this.opportunityData.potential_script == null
        && this.opportunityData.potential_revenue == null;
    },
    /**
     * Get the description for the selected period
     */
    periodDescription() {
      let description = '';

      if (!this.selectedPeriod.year) {
        return 'No Period';
      }

      if (this.selectedPeriod.quarter) {
        description = description + this.quarters.find(x => x.value === this.selectedPeriod.quarter).name + ' - '
      }
      if (this.selectedPeriod.month) {
        description = description + this.months.find(x => x.value === this.selectedPeriod.month).name + ' - '
      }

      description = description + this.selectedPeriod.year;

      return description;
    }
  },
  watch: {
    /**
     * Watch for changes to the selected category
     * If 'best' or 'worst' is selected, set the subcategory to the first item in the respective list
     * @param {String} subcategory 
     */
    subcategoryType(subcategory) {
      console.log('This is the subcategory type:', subcategory)
      if (subcategory == 'best'
        && this.categoryListData
        && this.categoryListData.best_categories
        && this.categoryListData.best_categories.categories
        && this.categoryListData.best_categories.categories.length > 0) {
        this.selectedSubcategory = this.categoryListData.best_categories.categories[0].category;
      } else if (subcategory == 'worst'
        && this.categoryListData
        && this.categoryListData.worst_categories
        && this.categoryListData.worst_categories.categories
        && this.categoryListData.worst_categories.categories.length > 0) {
        this.selectedSubcategory = this.categoryListData.worst_categories.categories[0].category;
      } else {
        this.selectedSubcategory = null;
      }
      console.log('This is the selected subcategory:', this.selectedSubcategory);
    },
    /**
     * Watch for changes to the selected category
     * If the category is changed, reset the opportunity calculator
     */
    selectedCategory() {
      this.resetOpportunityCalculator();
      this.selectedSubcategory = null;
    },
    /**
     * Watch for changes to the adherenceAdjustmentValue, setting the adherenceAdjustment flag
     * @param {String} value 
     */
    adherenceAdjustmentValue(value) {
      if (value) {
        this.adherenceAdjustment = true;
      } else {
        this.adherenceAdjustment = false;
      }
    },
    /**
     * Watch for changes to the pricePerScriptAdjustmentValue, setting the pricePerScriptAdjustment flag
     * @param {String} value 
     */
    pricePerScriptAdjustmentValue(value) {
      if (value) {
        this.pricePerScriptAdjustment = true;
      } else {
        this.pricePerScriptAdjustment = false;
      }
    }
  },
  /**
   * On mount, get the pharmacy info and the pharmacy stats
   */
  async mounted() {
    // Get 5 most recent years to populate the year dropdown
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.years.push({
        name: currentYear - i,
        value: currentYear - i
      });
    }

    await this.initPharmacyInfo();
    await this.getPharmacyStats();
  },
  methods: {
    /**
     * Get the pharmacy stats
     * @returns {Promise} - The promise for the pharmacy stats request
     */
    async getPharmacyStats() {
      this.$store.dispatch('getRefreshToken');
      this.pharmacyStatsData = {};
      this.pharmacyStatsError = '';
      this.loadingStatsData = true;
      try {
        const res = await axios.post('/api/enterprise-pharmacy-stats', this.pharmacyStatsPayload, this.$store.state.header);
        this.handlePharmacyStatsSuccess(res);
      } catch (err) {
        this.handlePharmacyStatsError(err);
      }
    },
    /**
     * Handle the pharmacy stats success case
     * @param {Object} res 
     */
    handlePharmacyStatsSuccess(res) {
      console.log('The pharmacy stats response is', res.data);
      this.loadingStatsData = false;
      this.pharmacyStatsData = res.data;
    },
    /**
     * Handle the pharmacy stats error case
     * @param {Object} err 
     */
    handlePharmacyStatsError(err) {
      console.log('The pharmacy stats error is', err.response.data);
      this.loadingStatsData = false;
      // If the token is expired
      if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
        console.log('yes, it does include expired')
        this.$router.push('/login');
      }
      this.pharmacyStatsError = err.response.data;
    },
    /**
     * Initialize the pharmacy information
     * @returns {Promise} - The promise for the enterprise-init-info request
     */
    async initPharmacyInfo() {
      this.$store.dispatch('getRefreshToken');
      this.pharmacies = [];
      this.pharmacyInfoError = '';
      this.pharmacyInfoLoaded = false;
      // If the categoryList is already loaded, change to get loading overlays
      if (this.categoryListLoaded) {
        this.categoryListLoaded = false;
        this.gridApi.showLoadingOverlay();
      }

      try {
        const res = await axios.post('/api/enterprise-init-info', this.initInfoPayload, this.$store.state.header);
        this.handlePharmacyInfoSuccess(res);
        await this.getCategoryList();
      } catch (err) {
        this.handlePharmacyInfoError(err);
      }
    },
    /**
     * Handle the pharmacy info success case
     * @param {Object} res
     */
    handlePharmacyInfoSuccess(res) {
      console.log('The pharmacy info response is', res.data);
      this.pharmacyInfoLoaded = true;
      this.pharmacies = res.data.pharmacies;
      this.selectedPharmacies = res.data.pharmacies;
      this.categories = res.data.categories;
      // Set the selected category to the first item in the list
      if (this.categories.length) {
        this.selectedCategory = this.categories[0];
      }
    },
    /**
     * Handle the pharmacy info error case
     * @param {Object} err
     */
    handlePharmacyInfoError(err) {
      console.log('The pharmacy info error is', err.response.data);
      this.pharmacyInfoLoaded = true;
      // If the token is expired
      if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
        console.log('yes, it does include expired')
        this.$router.push('/login');
      }
      this.pharmacyInfoError = err.response.data;
    },
    /**
     * Calculate the opportunity values
     * @returns {Promise} - The promise for the enterprise-opportunity-calculator request
     */
    async calculateOpportunity() {
      this.$store.dispatch('getRefreshToken');
      this.resetOpportunityCalculator();
      this.loadingOpportunityData = true;

      try {
        const res = await axios.post('/api/enterprise-opportunity-calculator', this.opportunityCalculatorPayload, this.$store.state.header);
        this.handleOpportunityDataSuccess(res);
      } catch(err) {
        this.handleOpportunityDataError(err);
      }
    },
    /**
     * Handle the opportunity success case
     * @param {Object} res
     */
    handleOpportunityDataSuccess(res) {
      console.log('The opportunity data response is', res.data);
      this.loadingOpportunityData = false;
      this.opportunityData = res.data;
    },
    /**
     * Handle the opportunity error case
     * @param {Object} err
     */
    handleOpportunityDataError(err) {
      console.log('The opportunity data error is', err.response.data);
      this.loadingOpportunityData = false;
      // If the token is expired
      if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
        console.log('yes, it does include expired')
        this.$router.push('/login');
      }
      this.opportunityDataError = err;
    },
    /**
     * Get the category list
     * @returns {Promise} - The promise for the enterprise-pharmacy-table request
     */
    async getCategoryList() {
      this.categoryListLoaded = false;
      this.$store.dispatch('getRefreshToken');
      this.categoryListError = '';
      this.columnData = [];
      this.gridApi.showLoadingOverlay(); // show loading screen when fetching the list
      this.categoryListData = {
        "columns": [],
        "baseline": {},
        "selected_average": {},
        "pharmacies": [],
        "best_categories": {
          "categories": [],
        },
        "worst_categories": {
          "categories": [],
        }
      };

      try {
        const res = await axios.post('/api/enterprise-pharmacy-table', this.categoryListPayload, this.$store.state.header);
        this.handleCategoryListSuccess(res);
      } catch (err) {
        this.handleCategoryListError(err);
      }
    },
    /**
     * Handle the category list success case
     * @param {Object} res
     */
    handleCategoryListSuccess(res) {
      console.log('The category list response is', res.data);
      // Push the column headers to the columnData array
      this.categoryListData = res.data;
      this.gridApi.setGridOption('suppressFieldDotNotation', true); // If false, the table treats '.' in the columns headers as nested rows
      this.categoryListData.columns.forEach((column) => {
        this.columnData.push(column.field.split(' ').join('_') + '_revenue_value'); // Revenue Value
        this.columnData.push(
          [
            column.field.split(' ').join('_') + '_adherent_script_percent',
            column.field.split(' ').join('_') + '_adherence_performance'
          ])
      });

      // Add three pinned rows at the top - total, selected average and strong room average
      let total = {};
      this.categoryListData.columns.forEach((column) => {
        let key = column.field.replaceAll(' ', '_') + '_revenue_value';
        total[key] = column.total;
      })
      total['pharmacy_name'] = "TOTAL";

      let baseline = this.categoryListData.baseline;
      baseline['pharmacy_name'] = "STRONGROOM AVG";

      let selectedAverage = this.categoryListData.selected_average;
      selectedAverage['pharmacy_name'] = "SELECTED AVG";

      // Add pharmacies data to the table
      this.rowData = this.categoryListData.pharmacies;
      this.gridApi.setGridOption('pinnedTopRowData', [total, baseline, selectedAverage]);

      // IF row data is not empty, remove the loading overlay
      // ELSE show the no rows overlay
      if (this.rowData.length !== 0 && this.columnData.length !== 0) {
        this.gridApi.hideOverlay();
      } else {
        // remove any existing data in rowData to show no rows overlay
        this.rowData = []
        this.gridApi.setGridOption('pinnedTopRowData', []);
        this.gridApi.showNoRowsOverlay();
      }

      this.categoryListLoaded = true;
    },
    /**
     * Handle the category list error case
     * @param {Object} err
     */
    handleCategoryListError(err) {
      console.log('The category list error is', err.response.data);
      this.categoryListLoaded = true;
      this.gridApi.showNoRowsOverlay();
      // If the token is expired
      if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
        console.log('yes, it does include expired')
        this.$router.push('/login');
      }
      this.categoryListError = err.response.data;
    },
    /**
     * Reset the opportunity calculator
     */
    resetOpportunityCalculator() {
      this.opportunityData = {};
      this.opportunityDataError = ''
    },
    /**
     * Open the settings modal
     */
    openSettingsModal() {
      this.$store.dispatch('getRefreshToken');
      this.displaySettingsModal = true;
    },
    /**
     * Open the message history report modal
     */
    openMessageHistoryReport() {
      this.$store.dispatch('getRefreshToken');
      this.displayMessageHistoryReportModal = true;
    },
    /**
     * Get the color to display based on the rate
     * @param {Number} rate 
     */
    getRateColor(rate) {
      if (rate > 0) {
          return 'positive-rate';
      } else if (rate < 0) {
          return 'negative-rate';
      } else {
          return 'neutral-rate';
      }
    },
    /**
     * Get the icon to display based on the rate
     * @param {Number} rate 
     */
    getRateIcon(rate) {
      if (rate > 0) {
          return this.increasingArrowIconSrc;
      } else if (rate < 0) {
          return this.decreasingArrowIconSrc;
      } else {
          return this.neutralLineIconSrc;
      }
    },
    /**
     * Get the baseline value based on the column
     * @param {Object} column
     */
    getBaselineValue(column) {
      let value = this.categoryListData.baseline[column.values[0]];
      if (!value) {
        return "";
      }

      if (column.key.includes('Revenue')) {
        return '$' + Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(value)
      }
      else if (column.key.includes('Adherence') || column.key.includes('Loyalty')) {
        return this.unboundPercent(value)
      }
      else return value;
    },
    /**
     * Get the selected average value based on the column
     * @param {Object} column
     */
    getSelectedAverageValue(column) {
      let value = this.categoryListData.selected_average[column.values[0]];
      if (!value) {
        return "";
      }

      if (column.key.includes('Revenue')) {
        return '$' + Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(value)
      }
      else if (column.key.includes('Adherence') || column.key.includes('Loyalty')) {
        return this.unboundPercent(value)
      }
      else return value;
    },
    // Event to handle sort event
    onSort(event) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
    },
    /**
     * Export the category list date to a CSV
     * @returns {Promise} - The promise for the enterprise-pharmacy-table-csv request
     */
    async exportCSV() {
      this.exportingCSV = true;
      this.$store.dispatch('getRefreshToken');
      console.log('Exporting Data');
      try {
        const res = await axios.post('/api/enterprise-pharmacy-table-csv', this.categoryListPayload, this.$store.state.header);
        this.handleExportSuccess(res);
      } catch (err) {
        this.handleExportError(err);
      }
    },
    /**
     * Handle the export CSV success case
     * @param {Object} res
     */
     handleExportSuccess(res) {
      console.log('The export csv response is', res.data);
      // Get the CSV data and download it
      let csv = res.data;
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `${new Date().getFullYear()}_adherence_data.csv`;
      anchor.click();
      this.exportingCSV = false;
    },
    /**
     * Handle the export CSV error case
     * @param {Object} err
     */
     handleExportError(err) {
      console.log('The export csv response is', err.response.data);
      this.exportingCSV = false;
      // If the token is expired
      if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
        console.log('yes, it does include expired')
        this.$router.push('/login');
      }
      this.exportCSVError = err.response.data;
    },
    /**
     * Select the subcategory type
     * @param {String} type
     */
    selectSubcategoryType(type) {
      this.$store.dispatch('getRefreshToken');
      type = type.toLowerCase();
      this.subcategoryType = type;
    },
    /**
     * Calculate a percentage from a decimal, ensuring that the decimal is between 0 and 1
     * @param {Number} decimal 
     */
    boundPercent(decimal) {
      if (!decimal || Number.isNaN(decimal)) {
        return '';
      }
      if (decimal <= 1 && decimal >= 0) {
        return (decimal * 100).toFixed(2) + "%"
      }
      return 'Invalid decimal';
    },
    /**
     * Calculate a percentage from a decimal
     * @param {Number} decimal 
     */
    unboundPercent(decimal) {
      if (!decimal || Number.isNaN(decimal)) {
        return '0%';
      }
      return (decimal * 100).toFixed(2) + "%";
    },
    /**
     * Update the filters when the user confirms them in the settings modal
     * @param {Object} value 
     */
    updateFilters(value) {
      this.selectedCategory = value.selectedCategory;
      this.selectedPeriod = value.selectedPeriod;
      this.selectedState = value.selectedState;
      this.selectedPostcode = value.selectedPostcode;
      this.selectedPharmacies = value.selectedPharmacies;
      this.selectedPeriodType = value.selectedPeriodType;
      // Retrieve the category list and pharmacy stats
      // IF the previous fetch is successful, categories are not empty, hence no need to fetch again
      // ELSE the category list is empty, fetch initial info from the analytics server again to get categories info
      if (this.categories.length != 0) {
        this.getCategoryList();
      }
      else { 
        this.initPharmacyInfo();
      }
      this.getPharmacyStats();
    },
    /**
     * Close the settings modal
     */
    closeModal() {
      this.displaySettingsModal = false;
      this.displayMessageHistoryReportModal = false;
    },
    /**
     * Financials - revenue table grid settings when loaded
     */
    onGridReady(params) {
      this.gridApi = params.api;
      //  This Option auto adjusts the height based on the number of rows and also gives a min-height of 150px to the data rows section to show no rows message.
      if (!this.categoryListLoaded) {
        this.gridApi.showLoadingOverlay();
      }
    },
    /**
     * Financials - dynamic styles for revenue table rows. For top pinned rows - different styles for Total and Averages
     * For the data rows, to generate a striped row effect
     */
    getRowStyle(params) {
      if (params.node.rowPinned) {
        if (params.data['pharmacy_name'] === "TOTAL") {
          return {'background-color': "#2e97de!important", 'color':"#fff", 'font-weight':600, "font-size":"13pt", "text-align":"left"}
        }
        else {
          return {'background-color': "#e5f1fe!important", 'color':"#2e97de", 'font-weight':600, "font-size":"13pt", "text-align":"left"}
        }
      }
      // data rows for striped effect
      if (params.node.rowIndex % 2 === 1) {
        return { 'font-size':'1rem','background-color': '#fcfcfc' };
      }
      else {
        return { 'font-size':'1rem','background-color':'#ffffff'};
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.full-height {
  height: 100%;
}

.grid-container {
  display: grid;
  // min and max width on columns
  grid-template-columns: minmax(500px, 2fr) minmax(380px, 1fr);
  column-gap: 40px;
  flex: 1;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
  // Required to prevent grid overflow
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.right-col {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 1em;
  gap: 20px;
}

.category-combobox {
  flex: 1 1 300px;
}

.overview-stats {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  height: 80px;
  margin-bottom: 1em;
  overflow: auto;
}

.overview_card {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  justify-content: center;
}

.p-datatable :deep(.p-column-title) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.p-datatable-wrapper) {
  border: 1px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid $bordergrey;
}

:deep(.p-datatable-table) {
  table-layout: fixed;
}

.stats-value {
  margin: 5px;
}

.stats-top-row {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  padding: 0 5px;
  flex: 1;
}

.stats-heading {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.7;
  margin: 0;
  flex: 1;
}

.stats-percentage {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.overview_card + .overview_card {
  border-left: solid 2px $bordergrey;
}

.export {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  gap: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: $grey;
  border: 1px solid $bordergrey;
  border-bottom: none;
}

// TABLE KEY CSS
.positive-rate {
  color: green;
}

.negative-rate {
  color: red;
}

.neutral-rate {
  color: grey;
}

.key-container {
  display: flex;
  gap: 1.5em;
}

.key-icon {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
}

.key-heading {
  font-size: 11pt;
  font-weight: 600;
  color: $deepgrey;
}

.key-text {
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.p-progress-spinner {
  margin: 0 0 0 auto;
}

// TABLE CSS
.p-datatable-wrapper {
  border: 1px solid $bordergrey;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
}

:deep(.p-datatable) .table-main-header {
  font-size: 13pt;
  font-weight: 600 !important;
  color: black !important;
}

:deep(.p-datatable) th[class*="align-"] .p-column-header-content {
  align-items: center;
  justify-content: center;
}

:deep(.p-datatable) .table-sub-header {
  font-size: 12pt;
  font-weight: 500 !important;
  color: $deepgrey !important;
}

:deep(.p-datatable) .totals-row {
  background-color: $phoneorders !important;
  color: white !important;
  font-size: 13pt;
}

:deep(.p-datatable) .average-row {
  background-color: $babyblue !important;
  color: $phoneorders !important;
  font-size: 13pt;
}

.adherence-span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

.table-footer {
  display: flex;
  align-items: center;
}

.flex-right {
  margin-left: auto;
}

.no-borders * {
  border : 0px !important;
}

.align-center {
  text-align: center !important;
}

:deep(.p-tabview) {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

:deep(.p-tabview-panels) {
  padding: 0 !important;
}

:deep(.p-tabview-nav-link) {
  justify-content: center;
  background: $babyblue !important;
  height: 60px;
}

:deep(.p-tabview-header) {
  flex: 1;
  height: 58px;
}

.flex-section {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
  gap: 8px;
}

.flex-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-section h3 {
  font-size: 14pt;
}

.flex-section h4 {
  font-size: 12pt;
  margin: 0.5em 0 0.67em 0;
}

.adjustment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.adjustment .p-togglebutton {
  flex: 1 1 100px;
}

.input-span {
  flex: 3 1 100px;
}

.input-span .p-inputtext {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input,
.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $phoneorders;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-expand {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.output-calc {
  background-color: white;
  border-radius: 6px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
}

.opportunity-heading {
  padding: 0.5em 1em 0 0.5em;;
}

.opportunity-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 3.1875rem;
}

.opportunity-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.opportunity-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.potential-container {
  background-color: $phoneorders;
  border-radius: 6px;
  padding: 1em 0.5em 1em 0.5em;
}

.current-container {
  background-color: $babyblue;
  border-radius: 6px;
  border: 1px solid $phoneorders;
  padding: 1em 0.5em 1em 0.5em;
}

.output-current-data {
  font-size: 18pt;
  color: $phoneorders;
  margin: 0;
}

.potential-no-selection {
  font-size: 16pt;
  color: white;
  margin: 0;
  text-align: center;
  display: flex;
  gap: 1em;
  align-items: center;
}

.current-no-selection {
  font-size: 16pt;
  color: $phoneorders;
  margin: 0;
  text-align: center;
  display: flex;
  gap: 1em;
  align-items: center;
}

.output-current-label {
  font-size: 10pt;
  color: $phoneorders;
  opacity: 0.75;
  margin: 0;
}

.output-potential-data {
  font-size: 18pt;
  color: white;
  margin: 0;
}

.output-potential-label {
  font-size: 10pt;
  color: white;
  opacity: 0.75;
  margin: 0;
}

.stats-skeleton {
  height: 60px !important;
}
.ag-theme-quartz {
  --ag-font-size: 13pt;
  --ag-border: 1px solid #cacaca;
  --ag-wrapper-border-radius: 0 0 12px 12px;
}

.ag-cell {
  border-width: 0 0 1px 1px;
}
</style>