<template>
  <div
    class="view"
    style="height: fit-content; background-position: top center; background-repeat: no-repeat; background-size: cover; background-image: linear-gradient(to bottom, #FEFFFF 0%, 32.6605498790741%, #B3CEE2 65.3210997581482%, 82.6605498790741%, #8DE3DA 100%);"
  >
    <div class="header">
      <div class="container flex-row">
        <div>
          <a
            href="https://strongroom.ai/"
            target="_blank"
          >
            <img
              class="header-logo"
              :src="lockupLogoSrc"
              alt="StrongRoom AI Logo"
            >
          </a>
        </div>
        <div class="black-button">
          <router-link
            to="/login"
            target="_blank"
          >
            Login
          </router-link>
        </div>
      </div>
    </div>
    
    <div class="container main">
      <h1
        class="form-header"
        style="color: #00205B; font-size: 64px"
      >
        60 Day Dispense Calculator
      </h1>
      <div style="display: flex; align-items: center; margin-top: 20px; gap: 20px;">
        <p style="margin: 0;">
          Supported by
        </p>
        <a
          href="https://www.guild.org.au/"
          target="_blank"
        >
          <img
            style="width: 310px; height: auto;"
            :src="guildLogoSrc"
            alt="StrongRoom AI Logo"
          >
        </a>
        <p>
          for member use.
        </p>
      </div>
      
      <hr style="width:205px;text-align:left;margin-left:0; height: 5px; background-color: #008491; border: none; margin-top: 20px;">
      <Transition name="fade">
        <div v-if="!registered">
          <!-- DESCRIPTION SECTION -->
          <section
            class="section"
            style="padding-top: 0;"
          >
            <div class="main-para">
              <p>With the announcement of the Federal Government’s proposal to introduce 60 day dispensing, we empathise with and embody the concerns of Community Pharmacy.</p>
              <p>StrongRoom AI is an independent and Australian owned medication management platform. We have developed a free to use tool, supported by the Pharmacy Guild of Australia for member use, to assist Community Pharmacies and the industry as a whole in understanding the impact of this proposal, both on the viability of pharmacies, and the potential impact on patient health outcomes.</p>
              <p style="margin-bottom: 0;">
                Please note some important areas;
              </p> 
              <ol type="a">
                <li><strong>You own the data.</strong></li>
                <li>As part of this process you are consenting that the Guild will have access to patient de-identified, macro data until July 1, 2023. No other parties wil have access to this information. If you would llike for this to be removed, please email <a href="mailto:support@strongroom.ai">support@strongroom.ai</a> and we will delete the data and provide confirmation.</li>
                <li>The platform is built securely using the most up to date encryption standards including the following: AES 256 Encryption (military grade), ISO27001 certified, HIPAA compliant.</li>
                <li><strong>All outputs are de-identified and non-patient identifiable.</strong> We do use patient-identifiable informtion from your dispense software in order to perform necessary calculations, however no patient-identifiable information leaves your pharmacy.</li>
              </ol>
            </div>
            <div class="main-para important-text">
              <div class="purple-border"></div>
              <div class="important-text-content">
                <h2 style="margin: 0;">
                  Important
                </h2>
                <p>
                  The results from this calculator are not intended as an accurate forecast of your business performance, and do not represent an official statement on the 60 Day Dispensing Policy. 
                  This report should not be used as a substitute for financial advice. Although all care is taken to ensure that the information is correct at the time of publication, 
                  changes may occur over time and individual circumstances will vary. 
                  You should consider your own unique circumstances and, where necessary, seek financial advice in relation to any action taken.
                </p>
                <p>
                  The Pharmacy Guild of Australia (Guild) will use the information you submit via this form for confidential internal research purposes. 
                  Any analysis performed by the Guild of the information you provide via this form will only be performed and published using aggregated and de-identified information. 
                  We will not disclose your information to any third party unless doing so is authorised or required by law, or we have your consent to do so. 
                  You have the right to access, or seek correction of, the personal information we hold about you, or to make a privacy complaint. For more information please see our
                  <a
                    href="/Privacy_Policy.pdf"
                    target="_blank"
                  >Privacy Policy</a>.
                </p>
              </div>
            </div>
            <div class="sign-up-container">
              <h2>Sign Up Now For Access!</h2>
              <p>To start using the calculator, please enter your pharmacy’s details into the form below, and we will guide you through the process of calculating the impact of 60 day dispensing on your pharmacy.</p>
            </div>
          </section>
          <!-- FORM SECTION -->
          <section 
            class="section grey-section" 
            style="margin: 0; border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <div class="signup-container">
              <div class="form-container left-col signup-container__form">
                <InputText
                  v-model="registrationForm.username"
                  placeholder="Enter a Username"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.email"
                  placeholder="Business Email"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.first_name"
                  placeholder="First Name"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.last_name"
                  placeholder="Last Name"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.job_title"
                  placeholder="Job Title"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.phone"
                  placeholder="Phone Number"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.pharmacy_name"
                  placeholder="Pharmacy Name"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.pharmacy_phone"
                  placeholder="Pharmacy Phone"
                  class="grey-input flex-expand"
                />
                <Dropdown
                  v-model="registrationForm.dispense_source"
                  :options="dispensingSources"
                  option-label="name"
                  option-value="key"
                  placeholder="Dispensing Software"
                  class="flex-expand"
                />
                <vue-google-autocomplete
                  v-if="$store.getters.getMapsApiLoaded == true"
                  id="map"
                  classname="p-inputtext p-component grey-input flex-expand"
                  placeholder="Pharmacy Address"
                  country="au"
                  :enable-geolocation="true"
                  @placechanged="getAddressData"
                />
                <InputText
                  v-else
                  v-model="registrationForm.street_address"
                  placeholder="Pharmacy Address"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.suburb"
                  placeholder="Pharmacy Suburb"
                  class="grey-input flex-expand"
                />
                <Dropdown
                  v-model="registrationForm.state"
                  :options="states"
                  placeholder="Pharmacy State"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.postcode"
                  placeholder="Pharmacy Postcode"
                  class="grey-input flex-expand"
                  type="number"
                />
                <InputText
                  v-model="registrationForm.country"
                  placeholder="Pharmacy Country"
                  class="grey-input flex-expand"
                />
                <div
                  v-if="registrationForm.dispense_source == 'frednxt' || registrationForm.dispense_source == 'posworks'"
                  class="form-row two-col"
                >
                  <WarningMessage v-if="registrationForm.dispense_source == 'frednxt'">
                    Please ensure that the <a
                      href="https://surveys.fred.com.au/s3/Fred-NXT-Sensitive-Data-Access"
                      target="_blank"
                    >Fred NXT Sensitive Data Access Form</a> has been completed and submitted to Fred IT.
                    Read the installation instructions after signing up for further information.
                  </WarningMessage>
                  <WarningMessage v-else-if="registrationForm.dispense_source == 'posworks'">
                    Please note that Dispense Works is <strong>not</strong> currently supported for the 60 day dispense calculator. Please feel free to sign up, and we will email you when Dispense Works is supported.
                  </WarningMessage>
                </div>
                <div class="form-row two-col">
                  <Checkbox
                    v-model="privacy_statement"
                    input-id="privacy_statement"
                    :binary="true"
                  />
                  <label
                    for="privacy_statement"
                    style="font-size: 9pt;"
                  >
                    I agree with StrongRoom AI's
                    <a
                      href="/Privacy_Policy.pdf"
                      target="_blank"
                    >Privacy Policy</a>
                    and 
                    <a
                      href="/EULA.pdf"
                      target="_blank"
                    >Terms of Service</a>
                  </label>
                </div>
                <div class="form-row two-col">
                  <Checkbox
                    v-model="email_marketing"
                    input-id="email_marketing"
                    :binary="true"
                  />
                  <label
                    for="email_marketing"
                    style="font-size: 9pt;"
                  >
                    I agree to receive emails on StrongRoom AI's new products and services.
                  </label>
                </div>
                <VueRecaptcha
                  class="two-col"
                  style="margin: 0; display: table;"
                  :sitekey="siteKey"
                  @verify="verifyCaptcha"
                  @expired="expiredCaptcha"
                  @render="renderCaptcha"
                  @error="captchaError"
                />
                <Button
                  v-if="!loading"
                  class="purple-button two-col"
                  label="Sign Up Now"
                  :disabled="!privacy_statement || !captcha_verified"
                  @click="signup"
                />
                <div
                  v-else
                  class="flex"
                >
                  <ProgressSpinner
                    class="medium-icon"
                    stroke-width="8"
                    animation-duration=".75"
                    fill="white"
                    aria-label="Signing you up..."
                  />
                  Signing you up...
                </div>
              </div>
              <hr
                style="margin-right: -10px; width: 2px; background-color: grey; border: none; height: 573px; margin-top: 3px;"
                class="signup-container__vertline"
              >
              <div class="right-col signup-container__security">
                <div class="main-para">
                  We take security seriously. All data is encrypted and compliant with all relevant regulations.<br>
                  See our list of security accreditations below:
                </div>
                <div class="flex-icons">
                  <div class="image-div">
                    <img
                      class="cert-image"
                      :src="isoLogoSrc"
                      alt="ISO 27001 Certified"
                    >
                  </div>
                  <p>
                    <b>ISO 27001 Certified.</b><br>
                    StrongRoom AI has been certified as ISO27001 compliant. Our organisation implements best practices in the safe and secure management of information.
                  </p>
                  <div class="image-div">
                    <img
                      class="cert-image"  
                      :src="hipaaLogoSrc"
                      alt="HIPAA Compliant"
                    >
                  </div>
                  <p>
                    <b>HIPAA Compliant.</b><br>
                    Our systems are compliant with the Health Insurance Portability and Accountability Act (HIPAA) which sets the standard for sensitive patient data protection.
                  </p>
                  <div class="image-div">
                    <img
                      class="cert-image"
                      :src="aesLogoSrc"
                      alt="AES 256 Encryption"
                    >
                  </div>
                  <p>
                    <b>AES 256 Encryption.</b><br>
                    All data is encrypted using the Military Grade Advanced Encryption Standard (AES) with a 256-bit key.
                  </p>
                </div>
              </div>
            </div>
            <p
              v-if="registrationError"
              class="error-message"
            >
              {{ registrationError }}
            </p>
            <p
              v-if="registrationSuccess"
              class="success-message"
            >
              {{ registrationSuccess }}
            </p>
          </section>
          <section
            class="grey-section"
            style="margin-top: 30px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
          >
            <div style="max-width: 417px;">
              <img
                :src="fullSraiLogoSrc"
                alt="strongRoom AI"
                style="max-width: 100%; height: auto; width: auto;"
              >
            </div>
            <div>
              <p>StrongRoom AI is a global Medtech company that is leading innovation in Drug Management Systems in Community Pharmacy, Aged Care, and Hospitals.</p>
              <p>Our Community Pharmacy solution, StrongER, is a consolidated medication management platform that includes eCDR (S8/S4d), Opioid-Replacement Therapy, and Staged Supply.</p>
              <p>This calculator is free to use, and a small part of  <b> our new data analytics solution ‘StrongPro’</b>, which is designed to help community pharmacies make data-driven decisions to improve their business.</p>
            </div>
            <div class="app-logos">
              <div class="logo-item">
                <span>
                  Data Analytics <b>*NEW*</b>
                </span>
                <img 
                  :src="strongProLogoSrc"
                  alt="StrongPro Logo"
                >
              </div>
              <div class="logo-item">
                <span>
                  Pharmacy
                </span>
                <img 
                  :src="strongERLogoSrc"
                  alt="StrongER Logo"
                >
              </div>
              <div class="logo-item">
                <span>
                  Data Security
                </span>
                <img 
                  :src="strongShieldLogoSrc"
                  alt="StrongShield Logo"
                >
              </div>
              <div class="logo-item">
                <span>
                  Aged Care
                </span>
                <img 
                  :src="strongCareLogoSrc"
                  alt="StrongCare Logo"
                >
              </div>
              <div class="logo-item">
                <span>
                  Hospital
                </span>
                <img 
                  :src="strongHospitalLogoSrc"
                  alt="StrongHospital Logo"
                >
              </div>
            </div>
            
            <div
              class="main-para"
              style="margin-bottom: 0 !important;"
            >
              To learn more about our product suite and how we can help you, please visit the following links:
            </div>
            <div class="engagement-links">
              <a
                class="grey-button"
                href="https://strongroom.ai/"
                target="_blank"
              >
                Website
              </a>
              <a
                class="grey-button"
                href="https://strongroom.ai/demo/"
                target="_blank"
              >
                Register your interest
              </a>
              <a
                class="grey-button"
                href="https://www.facebook.com/groups/strongerusers"
                target="_blank"
              >
                Facebook Users Group
              </a>
            </div>
          </section>
          <section>
            <img
              :src="linkedinBannerSrc"
              alt="srai banner"
              style="width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;"
            >
          </section>
          <section
            class="grey-section"
            style="margin-top: 30px;"
          >
            <p>Please note that this calculator can be used by all pharmacies with a common dispensing software.</p>
            <p>
              For support queries, <a
                href="https://strongroom.ai/contact/"
                target="_blank"
              >please contact us</a>
            </p>
          </section>
        </div>
        <section
          v-else
          class="section"
        >
          <div class="main-para">
            Congratulations you have successfully registered for the 60 Day Dispensing Calculator!
            <br>
            You will receive an email shortly to your designated email address <b>{{ registrationForm.email }}</b> with your login details, instructions, and a download link.
            <br>
            Please read the instructions carefully to ensure you get the most out of the calculator.
          </div>
          <div
            class="flat-filled-button"
            label="Return to Home"
            @click="returnHome"
          >
            Return Home
          </div>
        </section>
      </Transition>
    </div>
  </div>
  <footer>
    <div class="footer-content">
      <div class="footer-logos">
        <div class="footer-logo">
          <img
            :src="whiteGuildLogoSrc"
            alt="Pharmacy Guild Logo"
          >
        </div>
        <div class="footer-logo">
          <img
            :src="lockupLogoWhiteSrc"
            alt="StrongRoom AI Logo"
          >
        </div>
      </div>
      <div class="footer-tnc">
        <p>
          <a
            href="/Privacy_Policy.pdf"
            target="_blank"
            style="color: white; text-decoration: none"
          >Privacy Policy</a>
        </p>
        <hr style="border-left: 1px solid white; height: 20px; width: 1px; display: inline; top: 0">
        <p>
          <a
            href="/EULA.pdf"
            target="_blank"
            style="color: white; text-decoration: none"
          >Terms of Service</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import WarningMessage from '@/components/messages/warning-message';
import axios from 'axios';

export default {
  name: 'PharmacyGuild',
  components: {
    VueRecaptcha,
    WarningMessage
  },
  data() {
    return {
      whiteGuildLogoSrc: require('@/assets/logos/white_guild_logo.svg'),
      linkedinBannerSrc: require('@/assets/banners/linkedin.png'),
      guildLogoSrc: require('@/assets/logos/black_guild_logo.svg'),
      lockupLogoSrc: require('@/assets/logos/Lockup_Colour_Black.svg'),
      lockupLogoWhiteSrc: require('@/assets/logos/Lockup_Colour_White.svg'),
      fullSraiLogoSrc: require('@/assets/logos/full_srai_logo.svg'),
      isoLogoSrc: require('@/assets/logos/iso_27001.png'),
      hipaaLogoSrc: require('@/assets/logos/hipaa.png'),
      aesLogoSrc: require('@/assets/logos/aes_256.png'),
      strongProLogoSrc: require('@/assets/logos/StrongPro_logo.svg'),
      strongERLogoSrc: require('@/assets/logos/StrongER_logo.svg'),
      strongCareLogoSrc: require('@/assets/logos/StrongCare_logo.svg'),
      strongShieldLogoSrc: require('@/assets/logos/StrongShield_logo.svg'),
      strongHospitalLogoSrc: require('@/assets/logos/StrongHospital_logo.svg'),
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      registrationForm: {
        username: '',
        first_name: '',
        last_name: '',
        job_title: '',
        email: '',
        phone: '',
        pharmacy_name: '',
        pharmacy_phone: '',
        dispense_source: '',
        street_address: '',
        country: 'Australia',
        postcode: '',
        state: '',
        suburb: '',
        city: ''
      },
      privacy_statement: '',
      email_marketing: true,
      captcha_verified: '',
      registered: false,
      registrationError: '',
      registrationSuccess: '',
      loading: false
    }
  },
  computed: {
    registrationPayload() {
      return {
        username: this.registrationForm.username,
        first_name: this.registrationForm.first_name,
        last_name: this.registrationForm.last_name,
        job_title: this.registrationForm.job_title,
        email: this.registrationForm.email,
        phone: this.registrationForm.phone,
        pharmacy_name: this.registrationForm.pharmacy_name,
        pharmacy_phone: this.registrationForm.pharmacy_phone,
        sources: [
          {
            name: this.registrationForm.dispense_source
          }
        ],
        street_address: this.registrationForm.street_address,
        country: this.registrationForm.country,
        postcode: this.registrationForm.postcode,
        state: this.registrationForm.state,
        suburb: this.registrationForm.suburb,
        city: this.registrationForm.city,
        privacy_statement: this.privacy_statement,
        email_marketing: this.email_marketing
      }
    }
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData(addressData, placeResultData) {
      // Calculate the address from subpremise, street_number, and route
      let street_address = '';
      if (addressData.subpremise) {
        street_address += addressData.subpremise + '/';
      }
      if (addressData.street_number) {
        street_address += addressData.street_number + ' ';
      }
      if (addressData.route) {
        street_address += addressData.route;
      }
      this.registrationForm.street_address = street_address;
      this.registrationForm.suburb = addressData.locality;
      this.registrationForm.state = addressData.administrative_area_level_1;
      this.registrationForm.city = addressData.administrative_area_level_2;
      this.registrationForm.postcode = addressData.postal_code;
      this.registrationForm.country = addressData.country;
      console.log('this is the address now', addressData, placeResultData, this.registrationForm);
    },
    /**
     * Signup the pharmacy
     * @returns {Promise<void>}
     */
    async signup() {
      this.registrationError = '';
      this.registrationSuccess = '';
      this.loading = true;

      await axios.post('/api/signup', this.registrationPayload)
      .then((response) => {
        this.handleRegistrationSuccess(response);
      })
      .catch((error) => {
        this.handleRegistrationError(this.handleAxiosError(error));
      });
    },
    /**
     * Handle the success response for registration
     * @param {Object} res
     */
    handleRegistrationSuccess(res) {
      console.log('The registration response is: ', res);
      this.loading = false;
      this.registrationSuccess = 'Registration successful. Please check your email for further instructions.';
      this.registered = true;
    },
    /**
     * Handle the error response for registration
     * @param {Object} err
     */
    handleRegistrationError(err) {
      console.log('The registration error is: ', err);
      this.loading = false;
      this.registrationError = err;
    },
    verifyCaptcha(response) {
      console.log("Captcha verified: ", response);
      this.captcha_verified = response;
    },
    expiredCaptcha() {
      console.log("Captcha expired");
      this.captcha_verified = '';
    },
    renderCaptcha(id) {
      console.log("Captcha rendered: ", id);
    },
    captchaError() {
      console.log("Captcha error");
      this.captcha_verified = '';
    },
    returnHome() {
      this.registered = false;
      this.clear();
    },
    clear() {
      this.registrationForm.username = '';
      this.registrationForm.first_name = '';
      this.registrationForm.last_name = '';
      this.registrationForm.job_title = '',
      this.registrationForm.email = '';
      this.registrationForm.phone = '';
      this.registrationForm.pharmacy_name = '';
      this.registrationForm.pharmacy_phone = '';
      this.registrationForm.dispense_source = '';
      this.registrationForm.street_address = '';
      this.registrationForm.country = 'Australia';
      this.registrationForm.postcode = '';
      this.registrationForm.state = '';
      this.registrationForm.suburb = '';
      this.registrationForm.city = '';
      this.privacy_statement = '';
      this.captcha_verified = '';
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

p {
  font-size: 1.25rem;
}

.view {
  justify-content: center;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.flex-row > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  border-bottom: 1px solid grey;
  position: relative;
  width: 100%;
}

.form-header {
    padding-top: 2rem;
}

.container {
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.header > .container {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  height: 5rem;
}

.header-logo {
  width: 12rem;
  height: 100%;
}

.black-button a {
  text-decoration: none;
  color: inherit;
}

.main {
  padding-bottom: 2rem;
}

.section {
  padding-top: 2rem;
}
.grey-section{
  background-color: #f3f4f4;
  padding: 32px;
  border-radius: 6px;
}

.main-para {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  ol {
    margin-top: 0px;
  }
}

.important-text{
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-top: 30px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.important-text-content{
  padding: 29px;
}

.sign-up-container{
  color: white;
  background-color: #101820;
  padding: 32px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.purple-border{
  background-color: #6930A7;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.flex-expand {
  flex: 1;
}

.flex-icons {
  display: grid;
  grid-template-columns: [first] 150px [line2] auto;
  gap: 1rem;
  p {
    font-size: 15px;
  }
}

.app-logos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
  gap: 15px;
}

.logo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-self: flex-start;
  flex: 1;
  max-width: 270px;
  text-align: left;
  gap: 0.5rem;

  img {
    max-height: 52px;
    min-height: 52px;
    align-self: flex-start;
    flex: 0 0 auto;
  }
}

.engagement-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 15px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.grey-button {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.image-div {
  width: 150px;
  text-align: center;
}

.cert-image {
  max-height: 100px;
  max-width: 150px;
  width: auto;
  height: auto;
}

.cert-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  p {
    margin: 0;
  }
}

.signup-container {
  height: 100%;
}

.signup-container__form {
  grid-area: form;
}

.signup-container__vertline {
  grid-area: vertline;
}

.signup-container__security {
  grid-area: security;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
}

.right-col {
  grid-column-start: 4;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
  max-width: 600px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}
.two-col {
  grid-area: span 1 / span 2;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.p-progress-spinner {
  margin: 0 0 0 0 !important;
}

.flex-right {
  margin-left: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.purple-button{
  background-color:#6930A7;
  color: white;
  font-weight: bold;
  border: none;
}

footer {
  background-color: #222222;
  color: white;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.footer-content {
  max-width: 1150px;
  margin: 0 auto;
  padding: 51px 0;

  p {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
  }
}

.footer-logos {
  display: flex;
  gap: 50px;
  max-width: 800px;
}

.footer-logo {
  flex: 1;

  img {
    max-width: 100%;
  }
}

.footer-tnc {
  display: flex;
  justify-content: flex-start;
  max-width: 400px;
  align-items: center;
}

@media (min-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1048px) {
  .signup-container {
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    height: 100%;
    grid-template-areas: 'form vertline security';
    grid-template-columns: minmax(500px, 1fr);
  }
}

@media (max-width: 1048px) {
  .signup-container__vertline {
    display: none;
  }
}

@media (min-width: 1320px) {
  .container {
    width: 1240px;
  }
}
</style>