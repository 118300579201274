<template>
  <div
    class="view"
    style="height: fit-content; background-position: top center; background-repeat: no-repeat; background-size: cover; background-image: linear-gradient(to bottom, #FEFFFF 0%, 32.6605498790741%, #B3CEE2 65.3210997581482%, 82.6605498790741%, #8DE3DA 100%);"
  >
    <!-- <div class="header">
      <div class="container flex-row">
        <div>
          <a
            href="https://strongroom.ai/"
            target="_blank"
          >
            <img
              class="header-logo"
              :src="lockupLogoSrc"
              alt="StrongRoom AI Logo"
            >
          </a>
        </div>
      </div>
    </div> -->
    <div v-if="loadingConsentForm">
      <ProgressSpinner
        class="medium-icon"
        stroke-width="8"
        animation-duration=".75"
        aria-label="Loading consent form..."
      />
      <p>Loading consent form...</p>
    </div>
    <div v-else-if="consentValidationError!==''">
      <p>Consent form is invalid / already submitted</p>
    </div>
    <div
      v-else 
      class="container main"
    >
      <h1
        class="form-header trial-header"
      >
        {{ consentForm.form_title }}
      </h1>
      <hr style="width:100%;text-align:left;margin-left:0; height: 5px; background-color: #008491; border: none; margin-top: 20px;">
      <div>
        <h3>{{ location.name }}</h3>
        <p>{{ location.street_address + ", " + location.suburb + ", " + location.state + " " + location.postcode }}</p>
        <p>{{ location.phone_number }}</p>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px; gap: 20px; flex-wrap: wrap">
        <a
          :href="recruiter_website"
          target="_blank"
        >
          <img
            style="width: auto; min-height: 35px; max-height: 100px;"
            :src="recruiter_logo"
            alt="Recruiter Logo"
          >
        </a>
        <p>Powered By </p>
        <a
          href="https://www.strongroom.ai/"
          target="_blank"
        >
          <img
            style="width: 220px; height: auto;"
            :src="strongProLogoSrc"
            alt="Strong Pro Logo"
          >
        </a>
      </div>
      
      <Transition name="fade">
        <div v-if="!registered">
          <!-- DESCRIPTION SECTION -->
          <section
            class="section"
            style="padding-top: 0;"
          >
            <div
              v-if="trial.toLowerCase()=== 'recall'" 
              class="main-para"
            >
              <p class="recall-intro">
                Dementia is the second leading cause of death in Australia. There are currently around 472,000 people affected by dementia in Australia, which is predicted to double in the next 35 years. The risk of dementia can be reduced by maintaining a healthy lifestyle, eating a healthy diet, and staying physically active.
              </p>
              <p class="recall-intro">
                Some studies suggest that medication to lower blood pressure might help reduce the risk of dementia but there is insufficient evidence for clear recommendations. The RECALL-Pilot trial aims to address this gap in the current body of research.
              </p>
              <h3>Study Details</h3>
              <ul>
                <li>There are no face-to-face appointments in this study except for two blood tests – participants will carry out the rest of the pilot trial online from home.</li>
                <li>Eligible adults aged 70 or older will be asked to take a low-dose blood pressure medication for 60 days.</li>
                <li>Participants will need to have an email address, access to the internet and a computer/tablet to complete online assessments and cognitive tests.</li>
              </ul>
            </div>
            <div 
              v-else
              class="main-para"
            >
              <p 
                v-for="(formattedIntro, index) in formattedIntros" 
                :key="index"
              >
                {{ formattedIntro }}
              </p>
              <h3>{{ consentForm.purpose_heading }}</h3>
              <p 
                v-for="(formattedPurpose, index) in formattedPurposes" 
                :key="index"
              >
                {{ formattedPurpose }}
              </p>
              <h3>{{ consentForm.criteria_heading }}</h3>
              <ul type="a">
                <li 
                  v-for="(formattedCriterion, index) in formattedCriteria" 
                  :key="index"
                >
                  {{ formattedCriterion }}
                </li>
              </ul>
            </div>
            <div class="sign-up-container">
              <h2>If you meet the selected criteria, sign up below:</h2>
            </div>
          </section>
          <!-- FORM SECTION -->
          <section 
            class="section grey-section" 
            style="margin: 0; border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <div class="signup-container">
              <div class="form-container signup-container__form">
                <InputText
                  v-model="registrationForm.first_name"
                  placeholder="First Name"
                  :disabled="patientDetailStatus.first_name"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.last_name"
                  placeholder="Last Name"
                  :disabled="patientDetailStatus.last_name"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.phone_number"
                  placeholder="Phone Number"
                  :disabled="patientDetailStatus.phone_number"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.email"
                  placeholder="Email"
                  :disabled="patientDetailStatus.email"
                  class="grey-input flex-expand"
                />
                <InputText
                  v-model="registrationForm.postcode"
                  placeholder="Postcode"
                  :disabled="patientDetailStatus.postcode"
                  class="grey-input flex-expand"
                  type="number"
                />
                <div class="form-row two-col">
                  <p 
                    v-if="trial.toLowerCase()==='recall'"
                    style="font-size: 15px;"
                  >
                    *By providing your contact information here, <span style="font-weight: 600;">you agree for your information to be shared with the clinical trial third party and to be taken to the study website where you can submit your Expression of Interest.</span> This is in accordance with legal requirements for patient consent for third-party contact.
                  </p>
                  <p 
                    v-else
                    style="font-size: 15px;"
                  >
                    *By providing your contact information, you consent to the sharing of your details with {{ recruiter }}. They may contact you regarding your participation in the mentioned Clinical Trial. This is in accordance with legal requirements for patient consent for third-party contact.
                  </p>
                </div>
                <VueRecaptcha
                  class="two-col captcha"
                  :sitekey="siteKey"
                  @verify="verifyCaptcha"
                  @expired="expiredCaptcha"
                  @render="renderCaptcha"
                  @error="captchaError"
                />
                <Button
                  v-if="!loading"
                  class="purple-button two-col"
                  label="Sign Up Now"
                  :disabled="!captcha_verified"
                  @click="signup"
                />
                <div
                  v-else
                  class="flex"
                >
                  <ProgressSpinner
                    class="medium-icon"
                    stroke-width="8"
                    animation-duration=".75"
                    fill="white"
                    aria-label="Signing you up..."
                  />
                  Signing you up...
                </div>
              </div>
            </div>
            <p
              v-if="registrationError"
              class="error-message"
            >
              {{ registrationError }}
            </p>
            <p
              v-if="registrationSuccess"
              class="success-message"
            >
              {{ registrationSuccess }}
            </p>
          </section>
        </div>
        <section
          v-else
          class="section"
        >
          <div class="main-para">
            Congratulations you have successfully registered for the {{ trial }} study!
            <br>
            Thank you for your valuable contribution to advancing medical research.
          </div>
        </section>
      </Transition>
    </div>
  </div>
  <footer>
    <div class="footer-content grey-section">
      <!-- <div
        v-if="trial.toLowerCase()==='recall'"
        class="footer-misc-text"
      >
        <b>Contact:</b>
        <p>To find out more about this project and its principal investigators or The George Institute please contact Tina Wall +61 410 411 983 or twall@georgeinstitute.org.au</p>
      </div> -->
      <div class="footer-logos">
        <div class="footer-logo">
          <img
            :src="lockupLogoSrc"
            alt="StrongRoom AI Logo"
          >
          <div class="footer-tnc">
            <p>
              <a
                href="https://strongroom.ai/privacy/"
                target="_blank"
                style="color: black; text-decoration: none;"
              >Privacy Policy</a>
            </p>
          </div>
        </div>
        <hr class="footer-logo-separator">
        <div class="footer-logo">
          <img
            :src="recruiter_logo"
            alt="Recruiter Logo"
          >
          <div class="footer-tnc">
            <p>
              <a
                :href="recruiter_privacy_policy"
                target="_blank"
                style="color: black; text-decoration: none"
              >Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <hr
        style="margin-right: -10px; width: 2px; background-color: grey; border: none; height: 50px; margin-top: 3px;"
        class="signup-container__vertline"
      > -->
  </footer>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import axios from 'axios';

export default {
  name: 'ClinicalTrialConsentFormPage',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      // evrimaLogoSrc: require('@/assets/logos/evrima_logo.svg'),
      lockupLogoSrc: require('@/assets/logos/Lockup_Colour_Black.svg'),
      lockupLogoWhiteSrc: require('@/assets/logos/Lockup_Colour_White.svg'),
      isoLogoSrc: require('@/assets/logos/iso_27001.png'),
      hipaaLogoSrc: require('@/assets/logos/hipaa.png'),
      aesLogoSrc: require('@/assets/logos/aes_256.png'),
      strongProLogoSrc: require('@/assets/logos/StrongPro_logo.svg'),
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loadingConsentForm: true,
      consentValidationError: '',
      registrationForm: {
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        postcode: null,
      },
      trial: '',
      patientDetailStatus: {
        first_name: false,
        last_name: false,
        email: false,
        phone_number: false,
        postcode: false,
      },
      captcha_verified: '',
      registered: false,
      registrationError: '',
      registrationSuccess: '',
      loading: false,
      consentId: null,
      location: {
        name: '',
        phone_number: '',
        street_address: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      consentForm: {
        form_title: '',
        intro: '',
        purpose_heading: '',
        purpose: '',
        criteria_heading: '',
        criteria: '',
      },
      recruiter: '',
      provider: '',
      recruiter_website: '',
      recruiter_privacy_policy: '',
      recruiter_logo: '',
      formattedIntros: '',
      formattedPurposes: '',
      formattedCriteria: '',
    }
  },
  computed: {
    registrationPayload() {
      return {
        first_name: this.registrationForm.first_name,
        last_name: this.registrationForm.last_name,
        email: this.registrationForm.email,
        phone_number: this.registrationForm.phone_number,        
        postcode: this.registrationForm.postcode,
      }
    }
  },
  async mounted() {
    if (this.$userback) {
      this.$userback.hide();
    }
    await this.getConsentForm();
  },
  methods: {
    
    async getConsentForm() {
      this.loadingConsentForm = true;

      await axios.post('/api/consent/'+this.$route.params.id, {})
      .then((res) => {
        this.handleConsentFormSuccess(res);
      })
      .catch((error) => {
        this.handleConsentFormError(this.handleAxiosError(error));
      });
    },
    /**
     * Handle the success response for consent form
     */
     handleConsentFormSuccess(res) {
      console.log(res.data.data);
      this.loadingConsentForm = false;
      // this.registrationForm.first_name= res.data.data.first_name;
      // this.registrationForm.last_name= res.data.data.last_name;
      // this.registrationForm.email= res.data.data.email;
      // this.registrationForm.phone_number= res.data.data.phone_number;
      // this.registrationForm.postcode= res.data.data.postcode;
      this.trial = res.data.data.trial;
      this.consentId = res.data.data.consent_id;
      this.location = res.data.data.location;
      this.consentForm = res.data.data.consent_form;
      this.recruiter = res.data.data.recruiter;
      this.provider = res.data.data.provider;
      this.recruiter_website = res.data.data.recruiter_website;
      this.recruiter_privacy_policy = res.data.data.recruiter_privacy_policy;
      console.log('The recruiter privacy is: ', res.data.data.recruiter_privacy_policy);
      this.recruiter_logo = require(`@/assets/logos/${res.data.data.recruiter_logo}`);

      this.formattedIntros = this.consentForm.intro.split('\n');
      this.formattedPurposes = this.consentForm.purpose.split('\n');
      this.formattedCriteria = this.consentForm.criteria.split('\n');
      this.formattedCriteria = this.formattedCriteria.map((criterion) => {
        criterion = criterion.replace('-' , '');
        return criterion.replace(/\\n/g, '');
      });

      for (const key in this.patientDetailStatus) {
        if (this.registrationForm[key]) {
          this.patientDetailStatus[key] = true;
        }
      }
    },
    /**
     * Handle the error response for consent form
     * @param {Object} err
     */
    handleConsentFormError(err) {
      this.consentValidationError = err;
      this.loadingConsentForm = false;
      console.log('The consent form error is: ', err);
    },
    /**
     * Signup the pharmacy
     * @returns {Promise<void>}
     */
    async signup() {
      this.registrationError = '';
      this.registrationSuccess = '';
      this.loading = true;

      if (!this.registrationForm.first_name || !this.registrationForm.last_name || !this.registrationForm.email || !this.registrationForm.phone_number || !this.registrationForm.postcode) {
        this.handleRegistrationError('Please fill in all the fields.');
        return;
      }

      await axios.post(`/api/consent/${this.consentId}/submit`, this.registrationPayload)
      .then((response) => {
        this.handleRegistrationSuccess(response);
      })
      .catch((error) => {
        this.handleRegistrationError(this.handleAxiosError(error));
      });
    },
    /**
     * Handle the success response for registration
     * @param {Object} res
     */
    handleRegistrationSuccess(res) {
      console.log('The registration response is: ', res);
      this.loading = false;
      this.registrationSuccess = 'Clinical Trial Registration successful.';
      this.registered = true;

      if (this.trial.toLowerCase() === 'recall') {
        window.location = 'https://redcap1.georgeinstitute.org/surveys/?s=E87N4NHN3YT7EKM9';
      }
    },
    /**
     * Handle the error response for registration
     * @param {Object} err
     */
    handleRegistrationError(err) {
      console.log('The registration error is: ', err);
      this.loading = false;
      this.registrationError = err;
    },
    verifyCaptcha(response) {
      console.log("Captcha verified: ", response);
      this.captcha_verified = response;
    },
    expiredCaptcha() {
      console.log("Captcha expired");
      this.captcha_verified = '';
    },
    renderCaptcha(id) {
      console.log("Captcha rendered: ", id);
    },
    captchaError() {
      console.log("Captcha error");
      this.captcha_verified = '';
    },
    clear() {
      this.registrationForm.first_name = '';
      this.registrationForm.last_name = '';
      this.registrationForm.email = '';
      this.registrationForm.phone_number = '';
      this.registrationForm.postcode = '';
      this.captcha_verified = '';
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

p {
  font-size: 1.25rem;
}

.view {
  justify-content: center;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.flex-row > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  border-bottom: 1px solid grey;
  position: relative;
  width: 100%;
}

.form-header {
    padding-top: 2rem;
}

.trial-header {
  color: #00205B; 
  font-size: 2em; 
  word-wrap: break-word
}

.container {
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.header > .container {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  height: 5rem;
}

.header-logo {
  width: 12rem;
  height: 100%;
}

.black-button a {
  text-decoration: none;
  color: inherit;
}

.main {
  padding-bottom: 2rem;
}

.section {
  padding-top: 2rem;
}
.grey-section{
  background-color: #f3f4f4;
  padding: 32px;
  border-radius: 6px;
}

.main-para {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  ol {
    margin-top: 0px;
  }
}
.recall-intro {
  font-size: 1.25rem;
  font-weight: 600;
}

.important-text{
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-top: 30px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.important-text-content{
  padding: 29px;
}

.sign-up-container{
  color: white;
  background-color: #101820;
  padding: 32px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.purple-border{
  background-color: #6930A7;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.flex-expand {
  flex: 1;
}

.flex-icons {
  display: grid;
  grid-template-columns: [first] 150px [line2] auto;
  gap: 1rem;
  p {
    font-size: 15px;
  }
}

.app-logos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
  gap: 15px;
}

.logo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-self: flex-start;
  flex: 1;
  max-width: 270px;
  text-align: left;
  gap: 0.5rem;

  img {
    max-height: 52px;
    min-height: 52px;
    align-self: flex-start;
    flex: 0 0 auto;
  }
}

.footer-logo-separator {
  width:2px;
  text-align:left;
  margin-left:0; 
  height: 100px; 
  background-color: grey; 
  border: none; 
  margin-top: 20px;
}

.engagement-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 15px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.grey-button {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.image-div {
  width: 150px;
  text-align: center;
}

.cert-image {
  max-height: 100px;
  max-width: 150px;
  width: auto;
  height: auto;
}

.cert-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  p {
    margin: 0;
  }
}

.signup-container {
  height: 100%;
}

.signup-container__form {
  grid-area: form;
}

.signup-container__vertline {
  grid-area: vertline;
}

.signup-container__security {
  grid-area: security;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
}

.right-col {
  grid-column-start: 4;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}
.two-col {
  grid-area: span 1 / span 2;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.p-progress-spinner {
  margin: 0 0 0 0 !important;
}

.flex-right {
  margin-left: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.purple-button{
  background-color:#6930A7;
  color: white;
  font-weight: bold;
  border: none;
}

footer {
  background-color: #f3f4f4;
  color: white;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.footer-content {
  max-width: 1150px;
  margin: 0 auto;
  padding: 51px 0;

  p {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
  }
}

.footer-misc-text {
  display: flex;
  flex-direction: column;

  b {
    font-size: 1rem;
    text-align: left;
    color: $deepgrey;
    font-weight: 600;
  }

  p {
    margin-top: 0;
    font-size: 0.75rem;
    text-align: left;
    color: $deepgrey;
    font-weight: 500;
  }
}

.footer-logos {
  display: flex;
  gap: 50px;
  max-width: 800px;
}

.footer-logo {
  flex: 1;

  img {
    display: flex;
    max-width: 300px;
    height: 80px;
  }
}

.captcha {
  margin: 0; 
  display: table;
}

.footer-tnc {
  display: flex;
  justify-content: flex-start;
  max-width: 400px;
  align-items: center;
}

@media (max-width: 768px) {
  .footer-logo-separator {
    width:100%;
    text-align:left;
    margin-left:0; 
    height: 2px; 
    background-color: grey; 
    border: none; margin-top: 10px;
  }

  .footer-logos {
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 420px) {
  .captcha {
    transform:scale(0.9);
    -webkit-transform:scale(0.9);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }

  .trial-header {
    font-size: 1.8em;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 390px) {
  .captcha {
    transform:scale(0.7);
    -webkit-transform:scale(0.7);
  }

  .trial-header {
    font-size: 1.5em;
  }
}

@media (max-width: 330px) {
  .captcha {
    transform:scale(0.6);
    -webkit-transform:scale(0.6);
  }
  .footer-logos {
    gap: 20px;
  }

  .trial-header {
    font-size: 1.3em;
  }
}

@media (max-width: 300px) {
  .captcha {
    transform:scale(0.53);
    -webkit-transform:scale(0.53);
  }
  .footer-logos {
    gap: 20px;
  }
}


@media (min-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1048px) {
  .signup-container {
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    height: 100%;
    grid-template-areas: 'form vertline security';
    grid-template-columns: minmax(500px, 1fr);
  }
}

@media (max-width: 1048px) {
  .signup-container__vertline {
    display: none;
  }
}

@media (min-width: 1320px) {
  .container {
    width: 1240px;
  }
}
</style>