<template>
  <div class="login-view">
    <div class="login-form">
      <img
        :src="srIconLogoSrc"
        alt="StrongRoom AI Icon"
        style="height:70px; object-fit: contain; align-self: flex-start;"
      >
      <div
        class="login-form-details"
      >
        <h2>Password Reset</h2>
        <Transition
          name="fade"
          appear
        />
        <InputText
          v-model="password"
          placeholder="Password"
          type="password"
          style="position: relative;"
          autocomplete="one-time-code"
        />
        <InputText
          v-model="password_confirm"
          placeholder="Confirm Password"
          type="password"
          style="position: relative;"
          autocomplete="one-time-code"
        />
        <div style="margin-top: 1em; display: grid;  width: 100%">
          <p
            class="flat-light-button"
            @click="loading ? '' : submitPasswordReset()"
          >
            Submit
          </p>
        </div>
        <div style="display: flex; flex-wrap: wrap; width: 24em">
          <ProgressSpinner
            v-if="loading"
            class="medium-icon"
            stroke-width="8"
            animation-duration=".75"
            fill="white"
          />
          <p
            v-if="loading"
            style="font-weight: bold;"
          >
            Submitting...
          </p>
          <p
            v-if="displayMessage"
            style="word-break: break-word; font-weight: bold;"
          >
            {{ displayMessage }}
          </p>
          <p
            v-if="passwordError"
            style="color: red; word-break:break-word; font-weight: bold;"
          >
            Warning: {{ passwordError }}
          </p>
        </div>
      </div>
    </div>
    <div class="login-context">
      <!-- STRONGROOM AI LOGO -->
      <img
        :src="fullLogoSrc"
        alt="StrongRoom AI Logo"
        class="login-logo"
      >
      <!-- CONTACT DETAILS -->
      <div class="contact-links">
        <a
          class="contact-item"
          href="tel:+61370654066"
        >
          <img
            :src="phoneIconSrc"
            alt="Phone Contact"
            class="medium-icon"
          >
          <p>
            (03) 7065 4066
          </p>
        </a>
        <a
          class="contact-item"
          href="mailto:support@strongroom.ai"
        >
          <img
            :src="emailIconSrc"
            alt="Email Contact"
            class="medium-icon"
          >
          <p>
            support@strongroom.ai
          </p>
        </a>
        <a
          class="contact-item"
          href="https://www.strongroom.ai"
          target="_blank"
        >
          <img
            :src="websiteIconSrc"
            alt="Website Contact"
            class="medium-icon"
          >
          <p>
            www.strongroom.ai
          </p>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  components: {
  },
  data() {
    return {
      fullLogoSrc: require('@/assets/logos/full_logo.svg'),
      phoneIconSrc: require('@/assets/icons/phone_icon.svg'),
      emailIconSrc: require('@/assets/icons/email_icon.svg'),
      websiteIconSrc: require('@/assets/icons/website_icon.svg'),
      srIconLogoSrc: require('@/assets/logos/short_logo_white.svg'),
      displaySuccessModal: false,
      loading: false,
      displayMessage: '',
      passwordError: '',
      password:'',
      password_confirm:'',
      reset_code:''
    }
  },
  computed: {
    resetPasswordPayload() {
      return {
        reset_token: this.reset_code,
        password: this.password,
        password_confirmation: this.password_confirm
      }
    }
  },
  mounted() {
    this.reset_code = this.$route.params.id;
    console.log('this is the password payload', this.reset_code);
  },
  methods: {
    /**
     * Submit the password reset request
     * @returns {Promise<void>}
     */
    async submitPasswordReset() {
      this.loading = true;
      this.displaySuccessModal = true;
      this.passwordError = '';
      this.displayMessage = 'Your password is being reset...';
      
      axios.post(`/api/user/reset-password`, this.resetPasswordPayload)
      .then(res => {
        this.handleResetPasswordSuccess(res);
      }).catch(err => {
        this.handleResetPasswordError(this.handleAxiosError(err));
      })
    },
    /**
     * Handle the reset password success case
     * @param {Object} res 
     */
    handleResetPasswordSuccess(res) {
      console.log('this is the password completion', res.data);
      this.displayMessage = 'Password successfully reset! Redirecting to Login...';
      this.loading = false;
      this.$router.push('/login');
    },
    /**
     * Handle the reset password error case
     * @param {Object} err 
     */
    handleResetPasswordError(err) {
      console.log('this is the error with password reset', err);
      this.displayMessage = '';
      this.passwordError = err;
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
}

.login-context {
  background-color: white;
  text-align: left;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  max-width: 500px;
  min-width: 200px;
  align-self: center;
  flex: 1;
}

.contact-links {
  display: flex;
  row-gap: 10px;
  column-gap: 50px;
  padding: 0px 0 50px 50px;
  flex-wrap: wrap;
}

.contact-item {
  display: flex;
  gap: 15px;
  align-items: center;
  align-self: flex-end;
  text-decoration: none;
  font-weight: 600;
  color: $strongblue;
  flex-wrap: nowrap;

  p {
    white-space: nowrap;
  }
}

.contact-item:link {
  text-decoration: none;
}

.contact-item:visited {
  text-decoration: none;
}

.contact-item:hover {
  text-decoration: underline;
}

.contact-item:active {
  text-decoration: underline;
}

.login-form {
  padding: 4em 4em;
  overflow: hidden;
  border-bottom-right-radius: 4em;
  background-color: $strongblue;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  flex: 1 0 350px;
  display: flex;
  flex-direction: column;
}

.login-form-details {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    text-align: left;
    color: white;
    margin: 0;
  }

  input {
    height: 50px;
    border-radius: 5px;
  }
}

.p-field-checkbox {
  text-align: left;
}

.p-field-checkbox label {
  color: white !important;
}

.login-form-fields {
    display: grid;
}

.form-error {
    margin: 0;
    margin-bottom: 1em;
    color: $error;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
