<template>
  <TimeRunnerModal
    v-if="$store.state.displayTimeRunnerModal"
    @close-modal="closeTimeRunnerModal"
  />
  <div
    v-if="!calculator && !pharmaprograms && !login && !notFound && !resetPassword && !clinicalTrialConsentForm && !unsubscribeEmailPage"
    class="app-container"
  >
    <SidebarNavigation
      style="z-index: 3"
    />
    <div class="content-container">
      <router-view />
    </div>
  </div>
  <router-view v-else />
</template>

<script>
import SidebarNavigation from '@/components/navigation/sidebar-navigation.vue';
import TimeRunnerModal from '@/components/modals/time-runner-modal.vue';
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: 'App',
  components: {
    SidebarNavigation,
    TimeRunnerModal
  },
  data() {
    return {
      displaySaveCacheModal: false,
      checkedCache: false,
      loggedIn: false
    };
  },
  computed: {
    calculator() {
      return this.$route.path == '/calculator' || this.$route.path == '/calculator/';
    },
    pharmaprograms() {
      return this.$route.path == '/pharmaprograms' || this.$route.path == '/pharmaprograms/';
    },
    clinicalTrialConsentForm() {
      if (this.$route.path.includes('/clinicaltrial')) {
        return true;
      }
      
      return false;
    },
    unsubscribeEmailPage() {
      if (this.$route.path.includes('/unsubscribe')) {
        return true;
      }
      
      return false;
    },
    login() {
      return this.$route.path == '/login' || this.$route.path == '/login/';
    },
    resetPassword() {
      return this.$route.path.includes('/reset-password/');
    },
    notFound() {
      return this.$route.name == 'NotFound';
    }
  },
  mounted() {
    // Load the Google Maps API
    const googleMapsApi = new Loader({
      apiKey: "AIzaSyDatvq61bLvAVOyRmYp2GFMEo4CELTOl0E",
      version: "weekly",
      libraries: [
        "places",
        "maps"
      ]
    });
    googleMapsApi.load().then(() => {
      console.log('Google Maps API loaded successfully');
      this.$store.commit('setMapsApiLoaded', true)
    })
    .catch(e => {
      console.log('Google Maps API failed to load:', e);
      this.$store.commit('setMapsApiLoaded', false)
    });
  },
  methods: {
    closeTimeRunnerModal() {
      this.$store.commit('setDisplayTimeRunnerModal', false);
    }
  }
};
</script>

<style lang="scss">
  @import "assets/css/main.scss";
</style>

<style lang="scss">
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content-container {
  padding-left: 130px;
  width: 100%;
}

.half-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: -1;
    background-image: linear-gradient(to right, #0ddcf3 , #0973dd);
}
</style>