<template>
  <div
    class="sidebar"
    :class="sidebarExpanded ? `sidebar-expanded` : ``"
    @mouseover="sidebarExpanded = true"
    @mouseleave="sidebarExpanded = false"
  >
    <div class="navigation-items">
      <!-- INTERNAL SETTINGS PAGE -->
      <router-link
        v-if="srLogin"
        to="/settings"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'Settings' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="settingsIconSrc"
            class="nav-icon"
            alt="Settings Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              Settings
            </p>
          </transition>
          <div
            v-if="$route.name == 'Settings'"
            class="active-bar"
          ></div>
        </div>
      </router-link>
      <!-- FINANCIALS PAGE -->
      <router-link
        v-if="dataAnalytics"
        to="/financials"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'Financials' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="financeIconSrc"
            class="nav-icon"
            alt="Profile Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              Financials
            </p>
          </transition>
          <div
            v-if="$route.name == 'Financials'"
            class="active-bar"
          ></div>
        </div>
      </router-link>
      <!-- CAMPAIGNS AND MARKETING PAGE -->
      <!-- <router-link
        v-if="dataAnalytics"
        to="/marketing"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'Campaigns and Marketing' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="marketingIconSrc"
            class="nav-icon"
            alt="Campaigns and Marketing Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              Campaigns
            </p>
          </transition>
          <div
            v-if="$route.name == 'Campaigns and Marketing'"
            class="active-bar"
          ></div>
        </div>
      </router-link> -->
      <!-- GUILD 60 DAY DISPENSING -->
      <router-link
        v-if="guildLogin"
        to="/guildsixtydd"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'Guild Sixty Day Dispensing' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="dashboardIconSrc"
            class="nav-icon"
            alt="Dashboard Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              Guild_60DD
            </p>
          </transition>
          <div
            v-if="$route.name == 'Guild Sixty Day Dispensing'"
            class="active-bar"
          ></div>
        </div>
      </router-link>
      <!-- COMPANY 60 DAY DISPENSING -->
      <router-link
        v-if="sixtyDaySignup"
        to="/sixtydd"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'Sixty Day Dispensing' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="dashboardIconSrc"
            class="nav-icon"
            alt="Dashboard Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              60DD
            </p>
          </transition>
          <div
            v-if="$route.name == 'Sixty Day Dispensing'"
            class="active-bar"
          ></div>
        </div>
      </router-link>
      <!-- S8 Management -->
      <router-link
        v-if="ecdr"
        to="/s8management"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'S8 Management' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="tabletsIconSrc"
            class="nav-icon"
            alt="S8 Management Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              S8
            </p>
          </transition>
          <div
            v-if="$route.name == 'S8 Management'"
            class="active-bar"
          ></div>
        </div>
      </router-link>
      <!--Clinical Trials Referrals Dashboard-->
      <router-link
        v-if="clinicalTrialsLogin"
        to="/referrals"
      >
        <div
          class="navigation-item"
          :class="$route.name == 'Referrals Dashboard' ? `active-navigation-item` : `inactive-navigation-item`"
        >
          <img
            :src="dashboardIconSrc"
            class="nav-icon"
            alt="Dashboard Icon"
          >
          <transition name="slide-fade">
            <p
              v-show="sidebarExpanded"
              class="navigation-item-text"
            >
              Referrals
            </p>
          </transition>
          <div
            v-if="$route.name == 'Referrals Dashboard'"
            class="active-bar"
          ></div>
        </div>
      </router-link>
      <img
        :src="srIconLogoSrc"
        class="sidebar-logo"
        alt="StrongRoom AI Logo"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarNavigation',
  data() {
    return {
      settingsIconSrc: require('@/assets/icons/settings_icon.svg'),
      financeIconSrc: require('@/assets/icons/finance_icon.svg'),
      dashboardIconSrc: require('@/assets/icons/dashboard_icon.svg'),
      marketingIconSrc: require('@/assets/icons/marketing_icon.svg'),
      srIconLogoSrc: require('@/assets/logos/short_logo_white.svg'),
      tabletsIconSrc: require('@/assets/icons/tablets_icon.svg'),
      sidebarExpanded: false,
    }
  },
  computed: {
    srLogin() {
      return JSON.parse(localStorage.getItem('sr_login') ?? false);
    },
    guildLogin() {
      return JSON.parse(localStorage.getItem('guild_login') ?? false);
    },
    dataAnalytics() {
      return JSON.parse(localStorage.getItem('data_analytics') ?? false);
    },
    ecdr() {
      return JSON.parse(localStorage.getItem('ecdr') ?? false);
    },
    sixtyDaySignup() {
      return JSON.parse(localStorage.getItem('sixty_day_signup') ?? false);
    },
    clinicalTrialsLogin() {
      return JSON.parse(localStorage.getItem('clinical_trials_login') ?? false);
    }
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 130px;
  height: 100%;
  border-bottom-right-radius: 50px;
  background-color: $strongblue;
  position: fixed;
  transition: all 0.5s ease;
  top: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.sidebar-expanded {
  width: 310px;
}

.navigation-items {
  margin-top: 130px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.navigation-items a {
  text-decoration: none;
}

.navigation-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 45px;
  align-items: center;
  text-align: left;
}

.inactive-navigation-item {
  opacity: 0.5;
}

.inactive-navigation-item:hover {
  opacity: 0.8;
}

.active-navigation-item {
  opacity: 1;
}

.active-bar {
  width: 7px;
  height: 35px;
  background-color: white;
  margin-left: auto;
}

.slide-fade-enter-active {
  transition: opacity 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: opacity 0.5s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.navigation-item-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0;
  color: white;
  margin-left: 10px;
  transition: all 0.5s ease;
  flex: 1;
  text-wrap: nowrap;
}

.visible {
  display: inline-flex;
}

.invisible {
  opacity: none !important;
}

.logo-container {
  height: 100%;
  position: relative;
}

.sidebar-logo {
  height: 40px;
  margin-top: auto;
  margin-bottom: 50px;
}

.nav-icon {
  flex: 0 0 40px;
  max-width: 40px;
}

.analytics-selected-icon {
    background: url('@/assets/icons/analytics_selected_icon.png');
    height: 40px;
    width: 40px;
    display: block;
    background-size: contain;
    background-repeat:   no-repeat;
    background-position: center center;
}

.analytics-unselected-icon {
    background: url('@/assets/icons/analytics_unselected_icon.png');
    height: 40px;
    width: 40px;
    display: block;
    background-size: contain;
    background-repeat:   no-repeat;
    background-position: center center;
}

.services-selected-icon {
    background: url('@/assets/icons/services_selected_icon.png');
    height: 40px;
    width: 40px;
    display: block;
    background-size: contain;
    background-repeat:   no-repeat;
    background-position: center center;
}

.services-unselected-icon {
    background: url('@/assets/icons/services_unselected_icon.png');
    height: 40px;
    width: 40px;
    display: block;
    background-size: contain;
    background-repeat:   no-repeat;
    background-position: center center;
}
</style>