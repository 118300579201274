<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ pharmacy.name }}
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="owner">Owner</label>
                  <InputText
                    id="owner"
                    v-model="ownerName"
                    class="disabled-input"
                    :readonly="true"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="owner_email">Owner Email</label>
                  <InputText
                    id="owner_email"
                    v-model="ownerEmail"
                    class="disabled-input"
                    :readonly="true"
                  />
                </div>
              </div>
              
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="pharmacy_address">Pharmacy Address</label>
                  <InputText
                    id="pharmacy_address"
                    v-model="pharmacyAddress"
                    class="disabled-input"
                    :readonly="true"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="pharmacy_phone">Pharmacy Phone</label>
                  <InputText
                    id="pharmacy_phone"
                    v-model="pharmacyPhone"
                    class="disabled-input"
                    :readonly="true"
                  />
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPharmacyModal",
  props: {
    pharmacy: {
      type: Object,
      required: true,
    },
    owner: {
      type: Object,
      required: true,
    },
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  computed: {
    /**
     * Return the owner's name
     */
    ownerName() {
      if (this.owner && this.owner.person && this.owner.person.personal_information) {
        return this.owner.person.personal_information.first_name + ' ' + this.owner.person.personal_information.last_name;
      }
      return '';
    },
    /**
     * Return the owner's email
     */
    ownerEmail() {
      if (this.owner) {
        return this.owner.email;
      }
      return '';
    },
    /**
     * Return the pharmacy's address
     */
    pharmacyAddress() {
      if (this.pharmacy) {
        return [this.pharmacy.street_address, this.pharmacy.suburb, this.pharmacy.state, this.pharmacy.postcode].join(' ');
      }
      return '';
    },
    /**
     * Return the pharmacy's phone number
     */
    pharmacyPhone() {
      if (this.pharmacy) {
        return this.pharmacy.phone_number;
      }
      return '';
    }
  },
  methods: {
    /**
     * Emit the close modal event
     */
     closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-header {
  color: black;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

</style>
