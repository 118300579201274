<template>
  <!-- PAGE FILTERS -->
  <div class="filters-row">
    <!-- SEARCH BOX -->
    <span class="p-input-icon-right">
      <i
        class="pi pi-search button"
        @click="getS8Stats"
      ></i>
      <InputText
        v-model="searchTerm"
        style="width: 350px;"
        placeholder="Search"
        class="grey-input"
        @keyup.enter="getS8Stats"
      />
    </span>
    <!-- REFRESH BUTTON -->
    <Button
      icon="pi pi-refresh"
      aria-label="Refresh"
      class="p-button-raised p-button-rounded no-shrink"
      :loading="loadingStatsData"
      @click="getS8Stats"
    />
  </div>
  <!-- TABLE DATA -->
  <ag-grid-vue
    class="ag-theme-quartz flex-item"
    :row-data="rowData.results"
    :column-defs="colDefs"
    :auto-size-strategy="autoSizeStrategy"
    :grid-options="gridOptions"
    :suppress-pagination-panel="true"
    @grid-ready="onGridReady"
    @row-clicked="onRowClicked"
  />
  <!-- PAGINATION LINKS -->
  <div
    class="pagination-links"
    style="align-self: center;"
  >
    <p 
      v-show="rowData.current_page > 1" 
      class="pagination-arrow"
      @click="displayPage( rowData.current_page - 1 )"
    > 
      &larr; 
    </p>
    <p> 
      Page {{ rowData.current_page }} of {{ rowData.last_page }} 
    </p>
    <p 
      v-show="rowData.current_page < rowData.last_page"
      class="pagination-arrow"  
      @click="displayPage( rowData.current_page + 1 )"
    > 
      &rarr; 
    </p>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue3"; // Vue Grid Logic
import axios from 'axios';

export default {
  name: "S8StatsTable",
  components: {
    AgGridVue,
  },
  emits: [
    'rowClicked'
  ],
  data() {
    return {
        searchTerm: '',
        selectedPharmacies: [],
        loadingStatsData: false,
        statsData: {},
        s8StatsError: '',
        rowData: [],
        colDefs: [
          { field: "location.name", headerName: "Pharmacy", minWidth: 160 },
          { field: "last_stocktake_taken_on", headerName: "Last Stocktake", minWidth: 80, valueFormatter: this.dateFormatter },
          { field: "overdue_products_count", headerName: "Overdue Products", minWidth: 80 },
          { field: "incoming_script_count", headerName: "Incoming Scripts", minWidth: 80 },
          { field: "incoming_invoice_count", headerName: "Incoming Invoices", minWidth: 80 },
          { field: "owing_script_count", headerName: "Owed Scripts", minWidth: 80 },
          { field: "pending_partial_supplies_count", headerName: "Partial Supplies", minWidth: 80 },
          { field: "pending_countersigned_count", headerName: "Pending Countersigned", minWidth: 80 },
          { field: "pending_destruction_count", headerName: "Pending Destructions", minWidth: 80 },
          { field: "active_opioid_replacement_oral_patient_count", headerName: "Active ODT Patients", minWidth: 80 },
          { field: "pending_opioid_replacement_oral_claim_count", headerName: "Pending ODT Claims", minWidth: 80 },
          { field: "active_staged_supply_patient_count", headerName: "Active SS Patients", minWidth: 80 },
          { field: "upload_time", headerName: "Last Upload", minWidth: 80, valueFormatter: this.dateFormatter }
        ],
        autoSizeStrategy: {
          type: "fitGridWidth"
        },
        gridOptions: {
          defaultColDef: {
            sortable: true,
            resizable: true,
            filter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
          rowClass: 'button'
        },
        gridApi: null,
    }
  },
  watch: {
    /**
     * Watch for changes in loadingStatsData. If true then showLoadingOverlay, else hide it
     */
    loadingStatsData: function(newVal) {
      if (this.gridApi) {
        if (newVal) {
          this.gridApi.showLoadingOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      }
    }
  },
  /**
   * On mount, get the s8 stats
   */
  async mounted() {
    this.getS8Stats();
  },
  methods: {
    /**
     * Get the S8 Stats
     * @returns {Promise} - The promise for the s8 stats
     */
    async getS8Stats() {
      this.$store.dispatch('getRefreshToken');
      this.rowData = [];
      this.loadingStatsData = true;
      this.s8StatsError = '';

      try {
        const res = await axios.post('/api/s8/stats/list', { "page": this.$store.state.s8StatsPage || 1, "name": this.searchTerm }, this.$store.state.header);
        this.handleS8StatsSuccess(res);
      } catch (err) {
        this.handleS8StatsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the s8 stats
     * @param {Object} res - The response object
     */
    handleS8StatsSuccess(res) {
      console.log('The S8 Stats response is: ', res.data);
      this.rowData = res.data;
      this.loadingStatsData = false;
    },
    /**
     * Handle the error response for the s8 stats
     * @param {Object} err - The error object
     */
    handleS8StatsError(err) {
      console.error('The S8 Stats error is: ', err);
      this.loadingStatsData = false;
      this.s8StatsError = err;
    },
    /**
     * Handle the page refresh for the s8 stats list
     * @param integer page - The page number
     */
    displayPage(page) {
      this.$store.state.s8StatsPage = page;
      this.getS8Stats();
    },
    /**
     * Format the date
     * @param {Object} params 
     */
    dateFormatter(params) {
      if (params.value) {
        return new Date(params.value).toLocaleDateString();
      }
      return '';
    },
    /**
     * When the grid is ready, set the gridApi and add a resize listener
     * @param {Object} params 
     */
    onGridReady(params) {
      this.gridApi = params.api;

      window.addEventListener('resize', () => {
        setTimeout(() => {
          if (!this.gridApi.isDestroyed()) {
            params.api.sizeColumnsToFit();
          }
        });
      });
    },
    /**
     * When a row is clicked, emit the rowClicked event
     * @param {Object} params 
     */
    onRowClicked(params) {
      this.$store.dispatch('getRefreshToken');
      this.$emit('rowClicked', params);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.filters-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  margin-bottom: 1em;
  gap: 20px;
}

.flex-item {
  flex: 1;
}

.ag-theme-quartz {
  --ag-font-size: 12px;
  --ag-wrapper-border-radius: 5px;
  --ag-header-background-color: white;
  --ag-font-family: Montserrat, Helvetica, Arial, sans-serif;
  --ag-row-border-style: none;
}
</style>