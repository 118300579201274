<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ trialEditMode ? "Edit": "Create" }} Clinical Trial
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <Accordion :active-index="0">
                <!-- CLINICAL TRIALS TAB -->
                <AccordionTab>
                  <template #header>
                    <div class="span">
                      <p>Clinical Trial</p>
                    </div>
                  </template>
                  <div class="flex-input">
                    <label for="trial_name">Trial Name</label>
                    <InputText
                      id="trial_name"
                      v-model="createEditTrialForm.name"
                      placeholder="Trial Name"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="trial_note">Note</label>
                    <Textarea
                      id="trial_note"
                      v-model="createEditTrialForm.note"
                      placeholder="Trial Note"
                      rows="4"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="trial_criteria">Eligibility Criteria</label>
                    <Textarea
                      id="trial_eligibility_criteria"
                      v-model="createEditTrialForm.eligibility_criteria"
                      placeholder="Eligibility Criteria"
                      rows="8"
                      class="grey-input"
                    />
                    <!-- <InputText
                  id="trial_description"
                  v-model="createEditTrialForm.eligibility_criteria"
                  placeholder="Eligibility Criteria"
                  class="grey-input"
                /> -->
                  </div>
                  <div class="form-row">
                    <div class="flex-input flex-item">
                      <label for="recruiter">Recruiter</label>
                      <Dropdown
                        id="recruiter"
                        v-model="selectedRecruiter"
                        :options="recruiterOptions"
                        :loading="loadingRecruiters"
                        option-label="recruiter_provider_name"
                        input-id="recruiter_provider_id"
                        data-key="recruiter_provider_id"
                        placeholder="Recruiter"
                        class="grey-input"
                      />
                      <!-- <InputText
                    id="recruiter"
                    v-model="createEditTrialForm.recruiter"
                    placeholder="Recruiter"
                    class="grey-input"
                  /> -->
                    </div>
                    <div class="flex-input flex-item">
                      <label for="provider">Provider</label>
                      <Dropdown
                        id="recruiter"
                        v-model="selectedProvider"
                        :options="providerOptions"
                        :loading="loadingProviders"
                        option-label="recruiter_provider_name"
                        input-id="recruiter_provider_id"
                        data-key="recruiter_provider_id"
                        placeholder="Provider"
                        class="grey-input"
                      />
                      <!-- <InputText
                    id="provider"
                    v-model="createEditTrialForm.provider"
                    placeholder="Provider"
                    class="grey-input"
                  /> -->
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="flex-input flex-item">
                      <!-- REBATE -->
                      <label for="rebate">Rebate</label>
                      <InputNumber
                        id="rebate"
                        v-model="createEditTrialForm.rebate"
                        placeholder="Rebate"
                        class="grey-input"
                      />
                    </div>
                    <div class="flex-input flex-item">
                      <!-- TEMPLATES MULTISELECT -->
                      <label for="templates">Template(s)</label>
                      <div
                        id="templates"
                        class="form-row flex-item"
                      >
                        <MultiSelect
                          v-model="selectedTemplates"
                          class="flex-item"
                          :options="filteredTemplates"
                          option-label="template_name"
                          input-id="template_id"
                          data-key="template_id"
                          :placeholder="templatesPlaceholder"
                          :max-selected-labels="maxSelectedTemplateLabels"
                          :loading="loadingTemplates"
                          :virtual-scroller-options="{ lazy:true, onLazyLoad: updateTemplateList, itemSize: 40, loading: lazyLoadingTemplates, showLoader: true, numToleratedItems: 3}"
                          :selected-items-label="selectedTemplatesItemsLabel"
                          :filter="true"
                          @filter="searchTemplateList"
                        />
                        <Button
                          icon="pi pi-plus"
                          aria-label="Add Template"
                          class="p-button-raised p-button-rounded strong-blue-bg"
                          @click="addGlobalTemplate"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <!-- START DATE -->
                    <div class="flex-input flex-item">
                      <label for="start_date">Start Date</label>
                      <Calendar
                        id="start_date"
                        v-model="createEditTrialForm.start_date"
                        show-icon
                        icon-display="input"
                        date-format="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        class="grey-input"
                      />
                    </div>
                    <!-- END DATE -->
                    <div class="flex-input flex-item">
                      <label for="end_date">End Date</label>
                      <Calendar
                        id="end_date"
                        v-model="createEditTrialForm.end_date"
                        show-icon
                        icon-display="input"
                        date-format="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        class="grey-input"
                      />
                    </div>
                  </div>
                  <div class="tab-footer">
                    <div
                      v-if="createEditTrialError && createEditTrialError !==''"
                      class="error-text"
                    >
                      <span
                        class="red-error-icon"
                      >!</span>
                      <span class="error-text-span">{{ createEditTrialError }}</span>
                    </div>
                    <div
                      v-else-if="clinicalTrialCreateEditSuccess && clinicalTrialCreateEditSuccess !==''"
                      class="success-message" 
                    >
                      <span class="error-text-span">{{ clinicalTrialCreateEditSuccess }}</span>
                    </div>
                    <div
                      class="black-button"
                      style="margin:auto 0;"
                      @click="createEditClinicalTrial"
                    >
                      {{ trialActionButtonText }}
                    </div>
                  </div>
                </AccordionTab>
                <!-- CONSENT FORM TAB -->
                <AccordionTab>
                  <template #header>
                    <div class="span">
                      <p>Consent Forms</p>
                    </div>
                  </template>
                  <div class="flex-input">
                    <label for="form_title">Form Title</label>
                    <InputText
                      id="form_title"
                      v-model="trialConsentForm.form_title"
                      placeholder="Title"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="intro">Intro</label>
                    <Textarea
                      id="intro"
                      v-model="trialConsentForm.intro"
                      placeholder="Intro"
                      :rows="4"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="purpose_heading">Purpose Heading</label>
                    <InputText
                      id="purpose_heading"
                      v-model="trialConsentForm.purpose_heading"
                      placeholder="Purpose Heading"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="purpose">Purpose</label>
                    <Textarea
                      id="purpose"
                      v-model="trialConsentForm.purpose"
                      placeholder="Purpose"
                      :rows="4"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="criteria_heading">Criteria Heading</label>
                    <InputText
                      id="criteria_heading"
                      v-model="trialConsentForm.criteria_heading"
                      placeholder="Criteria Heading"
                      class="grey-input"
                    />
                  </div>
                  <div class="flex-input">
                    <label for="criteria">Criteria</label>
                    <Textarea
                      id="criteria"
                      v-model="trialConsentForm.criteria"
                      placeholder="Criteria"
                      :rows="4"
                      class="grey-input"
                    />
                  </div>
                  <div class="tab-footer">
                    <div
                      v-if="createEditConsentFormError && createEditConsentFormError !==''"
                      class="error-text"
                    >
                      <span
                        class="red-error-icon"
                      >!</span>
                      <span class="error-text-span">{{ createEditConsentFormError }}</span>
                    </div>
                    <div
                      v-else-if="consentFormCreateEditSuccess && consentFormCreateEditSuccess !==''"
                      class="success-message" 
                    >
                      <span class="error-text-span">{{ consentFormCreateEditSuccess }}</span>
                    </div>
                    <div
                      class="black-button"
                      style="margin:auto 0;"
                      @click="createEditConsentForm"
                    >
                      {{ consentFormActionButtonText }}
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </slot>
        </div>
        <!-- FOOTER -->
        <!-- <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="createEditTrialError && createEditTrialError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ createEditTrialError }}</span>
          </div>
          <div
            v-else-if="clinicalTrialCreateEditSuccess && clinicalTrialCreateEditSuccess !==''"
            class="success-message" 
          >
            <span class="error-text-span">{{ clinicalTrialCreateEditSuccess }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="activate"
          >
            {{ actionButtonText }}
          </div>
        </div> -->
      </div>
      <AdminCreateEditGlobalTemplateModal
        v-if="displayAddGlobalTemplatesModal"
        :templates="[]"
        :create-from-clinical-trial="true"
        @global-template-created="onNewGlobalTemplateCreated"
        @close-modal="closeOpenModals"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AdminCreateEditGlobalTemplateModal from './admin-create-edit-global-template-modal.vue';
export default {
  name: 'AdminCreateEditClinicalTrialModal',
  components: {
    AdminCreateEditGlobalTemplateModal,
  },
  props: {
    /**
     * The selected trials
     * @type {Object}
     */
    clinicalTrial: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  emits: [
    'closeModal',
    'trialCreated',
    'trialEdited'
  ],
  data() {
    return {
      selectedClinicalTrial: [],
      createEditTrialForm: {
        name: '',
        note: '',
        eligibility_criteria: '',
        rebate: '',
        recruiter: '',
        provider: '',
        templates: [],
        start_date: null,
        end_date: null,
      },
      loadingCreateTrial: false,
      createEditTrialError: '',
      clinicalTrialCreateEditSuccess: '',
      selectedTemplates: [],
      templates: [],
      loadingTemplates: false,
      loadingTemplatesError: '',
      lazyLoadingTemplates: false, 
      currentTemplatesPage: 1,
      totalTemplatePages: 1,
      templateListPerPage: 1,
      searchTemplates: '',
      currentDate: new Date(),
      trialEditMode: false,
      trialActionButtonText: 'Create',
      consentFormEditMode: false,
      consentFormActionButtonText: 'Create',
      displayAddGlobalTemplatesModal: false,
      recruiterOptions: [],
      providerOptions: [],
      loadingRecruiters: true,
      loadingProviders: true,
      selectedRecruiter: null,
      selectedProvider: null,
      trialConsentForm: {
        form_title: '',
        intro: '',
        purpose_heading: '',
        purpose: '',
        criteria_heading: '',
        criteria: '',
      },
      createEditConsentFormError: '',
      consentFormCreateEditSuccess: '',
      loadingCreateConsentForm: false,
      loadingEditConsentForm: false,
    }
  },
  computed: {
    /**
     * Create Trial Payload
     */
    createEditTrialPayload() {
      let endDate = this.createEditTrialForm.end_date;
      let startDate = this.createEditTrialForm.start_date;

      if (startDate) {
        startDate.setHours(0, 0, 0);
        startDate = new Date(startDate.getTime());
      }

      if (endDate) {
        endDate.setHours(23, 59, 59);
        endDate = new Date(endDate.getTime());
      }

      let payload = {
        name: this.createEditTrialForm.name,
        note: this.createEditTrialForm.note,
        eligibility_criteria: this.createEditTrialForm.eligibility_criteria,
        recruiter_id: this.selectedRecruiter.recruiter_provider_id,
        provider_id: this.selectedProvider.recruiter_provider_id,
        rebate: this.createEditTrialForm.rebate,
        start_date: startDate,
        end_date: endDate,
        active: this.trialEditMode ? this.createEditTrialForm.active : true,
        template_ids: this.selectedTemplates.map(template => template.template_id),
      };

      return payload;
    },
    /**
     * Filter the Templates based on search term
     * @returns {Array} - filtered templates
     */
    filteredTemplates() {
      return this.templates.map(template => {
        return {
          template_name: template.template_name,
          template_id: template.template_id
        }
      });
    },
    /**
     * Return the Templates placeholder text
     */
    templatesPlaceholder() {
      return this.loadingTemplates ? 'Loading Templates' : 'Select Templates';
    },
    /**
     * Pharmacy selected items label text
     */
    selectedTemplatesItemsLabel() {
      return this.selectedTemplates && this.selectedTemplates.length ? `${this.selectedTemplates.length} Templates Selected` : 'Select Templates';
    },
    /**
     * Return the max number of selected template labels
     */
    maxSelectedTemplateLabels() {
      if (this.selectedTemplates.length > 0) {
        // If selected templates are not in the list of templates, return 0
        if (this.selectedTemplates.every(selectedTemplate => this.templates.some(template => template.template_id === selectedTemplate.template_id))) {
          return 2;
        }
        else return 0;
      }
      else return 0;
    },
    /**
     * The get templates payload
     * @returns {Object}
     */
    getTemplatesPayload() {
      let payload = {
        page: this.currentTemplatesPage,
        global_template: true,
        type: ["sms", "email", "both"],
        paginate: true,
      }
      if (this.searchTemplates !== '') {
        payload.name = this.searchTemplates;
      }
      return payload;
    },
    /**
     * Get recruiters payload
     * @returns {Object}
     */
    getRecruitersPayload() {
      return {
        name: '',
        'recruiter_provider': 'recruiter',
        page: 1,
      }
    },
    /**
     * Get providers payload
     * @returns {Object}
     */
    getProvidersPayload() {
      return {
        name: '',
        'recruiter_provider': 'provider',
        page: 1,
      }
    },
  },
  async mounted() {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    this.createEditTrialForm.start_date = new Date(startDate.getTime());

    this.selectedClinicalTrial = this.clinicalTrial;

    if (!this.selectedClinicalTrial || !this.selectedClinicalTrial.trial_id) {
      this.trialEditMode = false;
      this.trialActionButtonText = 'Create';
      this.consentFormActionButtonText = 'Create';
    }
    else {
      this.trialEditMode = true;
      this.trialActionButtonText = 'Update';

      this.createEditTrialForm.name = this.selectedClinicalTrial.name;
      this.createEditTrialForm.note = this.selectedClinicalTrial.note;
      this.createEditTrialForm.eligibility_criteria = this.selectedClinicalTrial.eligibility_criteria;
      // this.createEditTrialForm.recruiter = this.selectedClinicalTrial.recruiter;
      // this.createEditTrialForm.provider = this.selectedClinicalTrial.provider;
      this.createEditTrialForm.rebate = this.selectedClinicalTrial.rebate;
      this.createEditTrialForm.start_date = this.selectedClinicalTrial.start_date ? new Date(this.selectedClinicalTrial.start_date) : null;
      this.createEditTrialForm.end_date = this.selectedClinicalTrial.end_date ? new Date(this.selectedClinicalTrial.end_date) : null;
      this.createEditTrialForm.active = this.selectedClinicalTrial.active;

      this.selectedRecruiter = {
        recruiter_provider_id: this.selectedClinicalTrial.recruiter.recruiter_provider_id,
        recruiter_provider_name: this.selectedClinicalTrial.recruiter.name,
        recruiter_or_provider: this.selectedClinicalTrial.recruiter.recruiter_or_provider
      };
      
      this.selectedProvider = {
        recruiter_provider_id: this.selectedClinicalTrial.provider.recruiter_provider_id,
        recruiter_provider_name: this.selectedClinicalTrial.provider.name,
        recruiter_or_provider: this.selectedClinicalTrial.provider.recruiter_or_provider
      };

      this.selectedClinicalTrial.templates.forEach(template => {
        this.selectedTemplates.push({
          template_name: template.name,
          template_id: template.id
        });
      });

      await this.getConsentForms();
    }

    this.loadingTemplates = true;

    await Promise.all([
      this.getRecruitersProviders(true),
      this.getTemplates(),
    ]);
    await this.getRecruitersProviders(false);
  },
  methods: {
    /**
     * Select the measurement type
     * @param {String} type
     */
    selectMeasurementType(type) {
      this.$store.dispatch('getRefreshToken');
      type = type.toLowerCase();
      this.createEditTrialForm.measure_by = type;
    },
    /**
     * Select the goal type
     * @param {String} type
     */
    selectGoalType(type) {
      this.$store.dispatch('getRefreshToken');
      if ((type === 'adherence' || type === 'loyalty') && this.createEditTrialForm.measure_by === 'value') {
        this.createEditTrialForm.measure_by = '';
      } 
    },
    /**
     * Get the list of templates for the trial
     */
    async getTemplates() {
      this.loadingTemplates = true;
      this.$store.dispatch('getRefreshToken');
      this.loadingTemplatesError = '';

      if (this.currentTemplatesPage === 1) {
        this.templates = [];
      }

      try {
        const res = await axios.post('/api/template/admin/search', this.getTemplatesPayload, this.$store.state.header);
        this.handleGetTemplatesSuccess(res);
      } catch (err) {
        this.handleGetTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get templates endpoint
     * @param {Object} res
     */
    handleGetTemplatesSuccess(res) {
      console.log('The get templates response is: ', res);
      let templates = res.data.results;
      this.currentTemplatesPage = res.data.current_page;
      this.totalTemplatePages = res.data.page_count;
      this.templateListPerPage = res.data.per_page;

      // Loop through each of the returned templates and add to the templates list
      templates.forEach(template => {
        this.templates.push({
          template_name: template.name,
          template_id: template.uuid,
        });
      });  
      
      this.loadingTemplates = false;
    },
    /**
     * Handle the error response from the get templates endpoint
     * @param {Object} err
     */
    handleGetTemplatesError(err) {
      console.log('The get templates error is: ', err);
      this.loadingTemplates = false;
      this.loadingTemplatesError = err;
    },
    /**
     * Create the trial
    */
    createEditClinicalTrial() {
      this.$store.dispatch('getRefreshToken');
      this.createEditTrialError = '';
      let errors = [];
      if (this.createEditTrialForm.name === '') {
        errors.push('enter a trial name');
      }
      if (this.createEditTrialForm.eligibility_criteria === '') {
        errors.push('enter eligibility criteria');
      }
      if (!this.selectedRecruiter) {
        errors.push('enter a recruiter');
      }
      if (!this.selectedProvider) {
        errors.push('enter a provider');
      }
      if (this.createEditTrialForm.rebate === '') {
        errors.push('enter a rebate');
      }      
      if (this.selectedTemplates && this.selectedTemplates.length === 0) {
        errors.push('select atleast one template');
      }
      if (errors.length > 0) {
        // If there are multiple errors, add 'and' before the last error
        if (errors.length > 1) errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
        // Join the errors till the second last with a comma and the last with an 'and'
        this.createEditTrialError = `Please ${errors.join(', ')}.`;
        return;
      }
      this.loadingCreateTrial = true;
      console.log('The trial form is: ', this.createEditTrialForm);
      this.trialEditMode ? this.updateTrial() : this.createTrial();
    },
    /*
    * Create the consent form
    */
   createEditConsentForm() {
    this.$store.dispatch('getRefreshToken');
      this.createEditConsentFormError = '';
      let errors = [];
      if (this.trialConsentForm.form_title === '') {
        errors.push('enter a form title');
      }
      if (this.trialConsentForm.intro === '') {
        errors.push('enter an intro');
      }
      if (this.trialConsentForm.purpose_heading === '') {
        errors.push('enter a purpose heading');
      }
      if (this.trialConsentForm.purpose === '') {
        errors.push('enter a purpose');
      }
      if (this.trialConsentForm.criteria_heading === '') {
        errors.push('enter a criteria heading');
      }
      if (this.trialConsentForm.criteria === '') {
        errors.push('enter a criteria');
      }
      if (errors.length > 0) {
        // If there are multiple errors, add 'and' before the last error
        if (errors.length > 1) errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
        // Join the errors till the second last with a comma and the last with an 'and'
        this.createEditConsentFormError = `Please ${errors.join(', ')}.`;
        return;
      }
      this.loadingCreateConsentForm = true;
      console.log('The consent form is: ', this.trialConsentForm);
      this.consentFormEditMode ? this.updateConsentForm() : this.createConsentForm();
   },
    /**
     * Update the template list
     */
    async updateTemplateList(event) {
      !this.lazyLoadingTemplates && (this.lazyLoadingTemplates = true);

      //call paginated endpoint
      if (event.last >= (this.templates.length - 3) && this.currentTemplatesPage < this.totalTemplatePages) {
        this.currentTemplatesPage++;
        await this.getTemplates();
      }
      this.lazyLoadingTemplates = false;
    },
    /**
     * Search the template list
     */
    async searchTemplateList(event) {
      !this.lazyLoadingTemplates && (this.lazyLoadingTemplates = true);
      this.searchTemplates = event.value;
      this.currentTemplatesPage = 1;
      this.totalTemplatePages = 1;
      this.templateListPerPage = 1;
      await this.getTemplates();
      this.lazyLoadingTemplates = false;
    },
    /**
     * Create the trial
     */
    async createTrial() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateTrial = true;
      this.trialActionButtonText = 'Creating...';

      this.createEditTrialForm.templates = this.selectedTemplates.map(template => ({
        template_id: template.template_id,
        template_recommendation_id: null
      }));

      // Call the Create Trial Endpoint

      try {
        const res = await axios.post('/api/clinical-trial/admin/create', this.createEditTrialPayload, this.$store.state.header);
        this.handleTrialCreateSuccess(res);
      } catch (err) {
        this.handleTrialCreateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Trial Creation Success
     * @param {Object} res
     */
    handleTrialCreateSuccess(res) {
      console.log('The trial creation response is: ', res);
      this.loadingCreateTrial = false;
      this.trialActionButtonText = 'Create';
      this.clinicalTrialCreateEditSuccess = 'Trial Created Successfully';
      this.clear();

      this.$emit('trialCreated', res); // Emit the created Trial when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Trial Creation Error
     * @param {Object} err
     */
    handleTrialCreateError(err) {
      console.log('The Trial creation error is: ', err);
      this.loadingCreateTrial = false;
      this.trialActionButtonText = 'Create';
      this.createEditTrialError  = err;
    },
    /**
     * Update the trial
     */
    async updateTrial() {
      this.$store.dispatch('getRefreshToken');
      this.loadingEditTrial = true;
      this.trialActionButtonText = 'Updating...';

      // Call the Update trial Endpoint

      try {
        const res = await axios.post(`/api/clinical-trial/admin/${this.selectedClinicalTrial.trial_id}/edit`, this.createEditTrialPayload, this.$store.state.header);
        this.handleTrialUpdateSuccess(res);
      } catch (err) {
        this.handleTrialUpdateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Trial Update Success
     * @param {Object} res
     */
     handleTrialUpdateSuccess(res) {
      console.log('The trial update response is: ', res);
      this.loadingCreateTrial = false;
      this.clinicalTrialCreateEditSuccess = 'Clincial Trial Updated Successfully';
      this.trialActionButtonText = 'Update';
      this.$emit('trialEdited', res); // Emit the updated trial when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Trial Update Error
     * @param {Object} err
     */
     handleTrialUpdateError(err) {
      console.log('The Trial update error is: ', err);
      this.loadingCreateTrial = false;
      this.createEditTrialError  = err;
      this.trialActionButtonText = 'Update';
    },
    /**
     * Create the consent form
     */
    async createConsentForm() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateConsentForm = true;
      this.consentFormActionButtonText = 'Creating...';

      // Call the Create Consent Form Endpoint

      try {
        const res = await axios.post(`/api/clinical-trial/admin/${this.selectedClinicalTrial.trial_id}/consent-form/create`, this.trialConsentForm, this.$store.state.header);
        this.handleConsentFormCreateSuccess(res);
      } catch (err) {
        this.handleConsentFormCreateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Consent Form Creation Success
     * @param {Object} res
     */
    handleConsentFormCreateSuccess(res) {
      console.log('The consent form creation response is: ', res);
      this.loadingCreateConsentForm = false;
      this.consentFormActionButtonText = 'Create';
      this.consentFormCreateEditSuccess = 'Consent Form Created Successfully';
      this.consentFormEditMode = true;
      this.consentFormActionButtonText = 'Update';
    },
    /**
     * Handle Consent Form Creation Error
     * @param {Object} err
     */
    handleConsentFormCreateError(err) {
      console.log('The Consent Form creation error is: ', err);
      this.loadingCreateConsentForm = false;
      this.consentFormActionButtonText = 'Create';
      this.createEditConsentFormError  = err;
    },
    /**
     * Update the consent form
     */
    async updateConsentForm() {
      this.$store.dispatch('getRefreshToken');
      this.loadingEditConsentForm = true;
      this.consentFormActionButtonText = 'Updating...';

      // Call the Update Consent Form Endpoint

      try {
        const res = await axios.post(`/api/clinical-trial/admin/${this.selectedClinicalTrial.trial_id}/consent-form/edit`, this.trialConsentForm, this.$store.state.header);
        this.handleConsentFormUpdateSuccess(res);
      } catch (err) {
        this.handleConsentFormUpdateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Consent Form Update Success
     * @param {Object} res
     */
    handleConsentFormUpdateSuccess(res) {
      console.log('The consent form update response is: ', res);
      this.loadingCreateConsentForm = false;
      this.consentFormCreateEditSuccess = 'Consent Form Updated Successfully';
      this.consentFormActionButtonText = 'Update';
    },
    /**
     * Handle Consent Form Update Error
     * @param {Object} err
     */
    handleConsentFormUpdateError(err) {
      console.log('The Consent Form update error is: ', err);
      this.loadingCreateConsentForm = false;
      this.createEditConsentFormError  = err;
      this.consentFormActionButtonText = 'Update';
    },
    /**
     * Get the list of recruiters/providers
     * @param {Object} err
     */
    async getRecruitersProviders(isRecruiter = true) {
      this.$store.dispatch('getRefreshToken');
      try {
        let payload = {};
        if (isRecruiter) {
          this.loadingRecruiters = true;
          // this.recruiterOptions = [];
          payload = this.getRecruitersPayload;
        } else {
          this.loadingProviders = true;
          // this.providerOptions = [];
          payload = this.getProvidersPayload;
        }
        const res = await axios.post('/api/recruiter-provider/admin/search', payload, this.$store.state.header);
        this.handleRecruitersProvidersSuccess(res, isRecruiter);
      } catch (err) {
        this.handleRecruitersProvidersError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get recruiters/providers endpoint
     * @param {Object} res
     */
    handleRecruitersProvidersSuccess(res, isRecruiter = true) {
      console.log('The get recruiters/providers response is: ', res);
      let recruitersProviders = res.data.data;
      isRecruiter ? this.loadingRecruiters = false : this.loadingProviders = false;

      recruitersProviders.forEach(recruiterProvider => {
        if (isRecruiter) {
          this.recruiterOptions.push(recruiterProvider);
        } else {
          this.providerOptions.push(recruiterProvider);
        }
      });
    },
    /**
     * Handle the error response from the get recruiters/providers endpoint
     * @param {Object} err
     */
    handleRecruitersProvidersError(err) {
      console.log('The get recruiters/providers error is: ', err);
      this.loadingRecruiters = false;
      this.loadingProviders = false;
    },
    /**
     * Get the consent forms for the trial
     */
    async getConsentForms() {
      this.$store.dispatch('getRefreshToken');
      this.loadingEditConsentForm = true;
      try {
        const res = await axios.post(`/api/clinical-trial/admin/${this.selectedClinicalTrial.trial_id}/consent-form`, {}, this.$store.state.header);
        this.handleConsentFormsSuccess(res);
      } catch (err) {
        this.handleConsentFormsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get consent forms endpoint
     * @param {Object} res
     */
    handleConsentFormsSuccess(res) {
      console.log('The get consent forms response is: ', res);
      let consentForms = res.data.data;
      this.trialConsentForm.form_title = consentForms.form_title;
      this.trialConsentForm.intro = consentForms.intro;
      this.trialConsentForm.purpose_heading = consentForms.purpose_heading;
      this.trialConsentForm.purpose = consentForms.purpose;
      this.trialConsentForm.criteria_heading = consentForms.criteria_heading;
      this.trialConsentForm.criteria = consentForms.criteria;

      this.loadingEditConsentForm = false;
      this.consentFormEditMode = true;
      this.consentFormActionButtonText = 'Update';
    },
    /**
     * Handle the error response from the get consent forms endpoint
     * @param {Object} err
     */
    handleConsentFormsError(err) {
      console.log('The get consent forms error is: ', err);
      this.loadingEditConsentForm = false;
      if (err === 'No consent form found for this clinical trial.') {
        this.consentFormEditMode = false;
        this.consentFormActionButtonText = 'Create';
        console.log('No consent form found for this clinical trial.');
      }
    },
    /**
     * Clear the form
     */
    clear() {
      this.createEditTrialForm = {
          name: '',
          note: '',
          eligibility_criteria: '',
          recruiter: '',
          provider: '',
          templates: [],
          start_date: null,
          end_date: null,
        };
        this.selectedTemplates = [];
    },
    /**
     * When a new recommended template is created
     * @param {Object} template
     */
     onNewGlobalTemplateCreated(template) {
      this.$store.dispatch('getRefreshToken');
      // Add the newly created template to the list of templates
      this.templates.push({
        template_name: template.name,
        template_id: template.template_id,
      });
      // Add the newly created recommended template to the selected templates
      this.selectedTemplates.push({
        template_name: template.name,
        template_id: template.template_id,
      });
    },
    /**
     * Add a template
     */
     addGlobalTemplate() {
      this.$store.dispatch('getRefreshToken');
      this.displayAddGlobalTemplatesModal = true;
    },
    /**
     * Close the modal
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Close any open modals
     */
    closeOpenModals() {
      this.loadingCreateTrial = false;
      this.displayAddGlobalTemplatesModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

:deep(.p-accordion-header-link) {
  height: 4em;
  background: white;
  border: none;
  border-top: 1px solid $bordergrey;
}

:deep(.p-accordion-content) {
  border: none;
}

:deep(.p-accordion-content) p {
  margin: 0;
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

:deep(.p-calendar-w-btn .p-datepicker-trigger) {
  background: $strongblue;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

.success-message {
  color: $complete;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.tab-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1em 0em;
}

</style>  