import axios from 'axios';
import { createStore } from 'vuex';
import router from '@/router';

export const store = createStore({
  state: {
    subdomain: '',
    view: '',
    uuid: JSON.parse(localStorage.getItem('currentLocation')) ? JSON.parse(localStorage.getItem('currentLocation')).uuid : '',
    company: JSON.parse(localStorage.getItem('company')),
    userType: JSON.parse(localStorage.getItem('company')) ? JSON.parse(localStorage.getItem('company')).type : '',
    sr_login: JSON.parse(localStorage.getItem('sr_login') ?? false),
    guild_login: JSON.parse(localStorage.getItem('guild_login') ?? false),
    clinical_trials_login: JSON.parse(localStorage.getItem('clinical_trials_login') ?? false),
    sixty_day_signup: JSON.parse(localStorage.getItem('sixty_day_signup') ?? false),
    data_analytics: JSON.parse(localStorage.getItem('data_analytics') ?? false),
    ecdr: JSON.parse(localStorage.getItem('ecdr') ?? false),
    portal_eula: null,
    session_id: '',
    user: JSON.parse(localStorage.getItem('user') || null),
    recruiter: JSON.parse(localStorage.getItem('recruiter') || null),
    displayTimeRunnerModal: false,
    timeToLogOut: 0,
    currentLocation: JSON.parse(localStorage.getItem('currentLocation')),
    mapsApiLoaded: false,
    loadingRefresh: false,
    
    header: {
      headers: {
        'Authorization':`Bearer bubble`,
        'Content-Type': 'application/json',
        'Location': 'something'
      }
    },
    country: 'au',
  },
  getters: {
    getSubdomain: state => {
      return state.subdomain;
    },
    getMapsApiLoaded: state => {
      return state.mapsApiLoaded;
    },
    getSrLogin: state => {
      return state.sr_login;
    },
    getGuildLogin: state => {
      return state.guild_login;
    },
    getClinicalTrialsLogin: state => {
      return state.clinical_trials_login;
    },
    getRecruiter: state => {
      return state.recruiter;
    },
    getSixtyDaySignup: state => {
      return state.sixty_day_signup;
    },
    getDataAnalytics: state => {
      return state.data_analytics;
    },
    getEcdr: state => {
      return state.ecdr;
    },
    getPortalEula: state => {
      return state.portal_eula;
    }
  },
  mutations: {
    setSubdomain(state, subdomain){
      state.subdomain = subdomain;
    },
    setAuthorization(state) {
      state.header.headers['Authorization'] = `Bearer ${('; '+document.cookie).split(`; auth=`).pop().split(';')[0]}`
    },
    setUser(state, user) {
      state.session_id = user.session_id;
      state.user = user;
    },
    setCompany(state, id) {
      state.company = id;
    },
    setHeaderLocation(state,location) {
      state.header.headers['Location'] = location;
    },
    setToken(state,token) {
      state.header.headers['Authorization'] = `Bearer ${token}`
    },
    setTimeToLogOut(state,value){
      state.timeToLogOut = value
    },
    setDisplayTimeRunnerModal(state,value){
      state.displayTimeRunnerModal = value;
    },
    setMapsApiLoaded(state, value) {
      state.mapsApiLoaded = value;
    },
    setSrLogin(state, value) {
      localStorage.removeItem('sr_login');
      localStorage.setItem('sr_login', value);
      state.sr_login = value;
    },
    setGuildLogin(state, value) {
      localStorage.removeItem('guild_login');
      localStorage.setItem('guild_login', value);
      state.guild_login = value;
    },
    setClinicalTrialsLogin(state, value) {
      localStorage.removeItem('clinical_trials_login');
      localStorage.setItem('clinical_trials_login', value);
      state.clinical_trials_login = value;
    },
    setRecruiter(state, recruiter) {
      // localStorage.removeItem('recruiter');
      // localStorage.setItem('recruiter', recruiter);
      state.recruiter = recruiter;
    },
    setSixtyDaySignup(state, value) {
      localStorage.removeItem('sixty_day_signup');
      localStorage.setItem('sixty_day_signup', value);
      state.sixty_day_signup = value;
    },
    setDataAnalytics(state, value) {
      localStorage.removeItem('data_analytics');
      localStorage.setItem('data_analytics', value);
      state.data_analytics = value;
    },
    setEcdr(state, value) {
      localStorage.removeItem('ecdr');
      localStorage.setItem('ecdr', value);
      state.ecdr = value;
    },
    setPortalEula(state, value) {
      localStorage.removeItem('portal_eula');
      localStorage.setItem('portal_eula', value);
      state.portal_eula = value;
    }
  },
  actions: {
    // Updates the refresh token
    getRefreshToken(state) {
      if (state.loadingRefresh) return;
      state.commit('setAuthorization');
      let storedTime = JSON.parse(localStorage.getItem('lastRefresh'));
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      let timeDiff = (currentTime - storedTime) / (1000 * 60) % 60;

      console.log('this is the time since last refresh', timeDiff);
      if (timeDiff > 5) {
        state.loadingRefresh = true
        axios.post('/api/refresh', {}, this.state.header)
        .then(res => {
          state.commit('setToken', res.data.access_token);
          let cookie = "auth="+res.data.access_token+"; max-age="+3600+"; path=/";
          document.cookie = cookie;
          
          console.log('set the localstorage last refresh to current time', currentTime);
          localStorage.removeItem('lastRefresh');
          localStorage.setItem('lastRefresh', currentTime);
          state.loadingRefresh = false;
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired');
            localStorage.removeItem('lastRefresh');
            state.loadingRefresh = false;
            router.push('/login');
          }
        })
      } else {
        console.log('do not need to get refresh token');
      }
    },
    timeRunner(state) {
      let interval = setInterval(() => {
        // get local storage time
        let storedTime = JSON.parse(localStorage.getItem('lastRefresh'));
        // calculate 15mins ahead
        let expiryTime = storedTime + 900000;
        // get current time
        let currentTime = new Date();
        currentTime = currentTime.getTime();
        // calc time to expiry
        let timeToExpiry = expiryTime - currentTime;
        // run timer using setInterval

        let timer = timeToExpiry/1000;
        timer--;
        this.loadingDelay = true;
        state.commit('setTimeToLogOut', timer)
        // once timer gets to 0, direct to login page and stop running
        if (timer < 1) {
          console.log('TIME TO RETURN TO LOGIN');
          localStorage.removeItem('lastRefresh');
          state.commit('setDisplayTimeRunnerModal', false);
          clearInterval(interval);

          // If on the PharmaPrograms page, refresh the page
          if (router.currentRoute.value.name == 'PharmaPrograms') {
            router.go();
          }
          // If on any other page, redirect to login
          else {
            router.push('/login');
          }
        } else {
          // run check to see if time diff is at or below 2mins
          // if condition met, display modal
          if (timer <= 120) {
            state.commit('setDisplayTimeRunnerModal', true);
          } else{
            state.commit('setDisplayTimeRunnerModal', false);
          }
        }
      }, 1000);
    }
  }
})