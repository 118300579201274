<template>
  <div class="flex-row">
    <button
      class="header-button"
      @click="openLinkLocationModal"
    >
      Link Location
    </button>
  </div>
  <DataTable
    v-model:expanded-row-groups="expandedRowGroups"
    :value="locations"
    :loading="loadingLocations"
    expandable-row-groups
    row-group-mode="subheader"
    group-rows-by="company.name"
  >
    <template #empty>
      No records found
    </template>
    <template #loading>
      Loading records, please wait...
    </template>
    <!-- TEMPLATE FOR COMPANY NAME -->
    <template #groupheader="slotProps">
      <span style="font-weight: 600;">
        {{ slotProps.data.company ? slotProps.data.company.name : '' }}
      </span>
    </template>
    <!-- LOCATION NAME -->
    <Column
      field="name"
      header="Name"
    />
    <!-- LOCATION ADDRESS -->
    <Column header="Address">
      <template #body="slotProps">
        <span>
          {{ slotProps.data.street_address }}, {{ slotProps.data.suburb }} {{ slotProps.data.state }} {{ slotProps.data.postcode }}, {{ slotProps.data.country }}
        </span>
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          icon="pi pi-trash"
          outlined
          rounded
          severity="danger"
          @click="openConfirmDeletion(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>
  <ConfirmDialog group="user_locations_confirmation" />
  <LinkLocationModal
    v-if="displayLinkLocationModal"
    :user="user"
    @close-modal="closeModal"
  />
</template>

<script>
import axios from 'axios';
import LinkLocationModal from '@/components/modals/link-location-modal.vue';

export default {
  components: {
    LinkLocationModal
  },
  props: {
    /**
     * The Selected User
     * @type {Object}
     */
    user: {
      type: Object,
      default() {
        return {
        }
      }
    },
    /**
     * The companies data
     * @type {Array}
     */
    companies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      locations: [],
      loadingLocations: false,
      locationsError: '',
      expandedRowGroups: [],
      displayLinkLocationModal: false
    }
  },
  computed: {
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    /**
     * Get the locations for the selected user
     */
    getLocations() {
      this.$store.dispatch('getRefreshToken');
      this.loadingLocations = true;
      this.locations = [];
      this.locationsError = '';

      axios.post(`/api/user/${this.user.uuid}/locations`, {}, this.$store.state.header)
        .then((response) => {
          this.handleGetLocationsSuccess(response);
        })
        .catch((error) => {
          this.handleGetLocationsError(this.handleAxiosError(error));
        })
    },
    /**
     * Handle the success response for the locations
     * @param {Object} res 
     */
    handleGetLocationsSuccess(res) {
      console.log('The get locations response is: ', res);
      this.loadingLocations = false;
      this.expandedRowGroups.forEach(location => {
        this.expandedRowGroups.push({
          [location.uuid]: false
        })
      })
      this.locations = res.data.data;
    },
    /**
     * Handle the error response for the locations
     * @param {Object} err 
     */
    handleGetLocationsError(err) {
      console.log('The get locations error is: ', err);
      this.loadingLocations = false;
      this.locationsError = err;
    },
    /**
     * Unlink the location from the user
     * @param {Object} location 
     */
    unlinkLocation(location) {
      this.$store.dispatch('getRefreshToken');
      this.loadingLocations = true;
      this.locationsError = '';

      axios.post('/api/location-unlink-login', { login_id: this.user.uuid, location_id: location.uuid }, this.$store.state.header)
        .then((response) => {
          this.handleUnlinkLocationSuccess(response);
        })
        .catch((error) => {
          this.handleUnlinkLocationError(this.handleAxiosError(error));
        })
    },
    /**
     * Handle the success response for the unlink location
     * @param {Object} res 
     */
    handleUnlinkLocationSuccess(res) {
      console.log('The unlink location response is: ', res);
      this.loadingLocations = false;
      this.getLocations();
    },
    /**
     * Handle the error response for the unlink location
     * @param {Object} err 
     */
    handleUnlinkLocationError(err) {
      console.log('The unlink location error is: ', err);
      this.loadingLocations = false;
      this.locationsError = err;
    },
    /**
     * Open the link location modal
     * @param {Object} company 
     */
     openLinkLocationModal() {
      this.displayLinkLocationModal = true;
    },
    /**
     * Close the open modals
     */
     closeModal() {
      this.displayLinkLocationModal = false;
    },
    /**
     * Open the confirm deletion dialog
     */
    openConfirmDeletion(location) {
      this.$confirm.require({
        header: 'Unlink Location',
        message: 'Are you sure you want to unlink this location?',
        icon: 'pi pi-exclamation-triangle',
        group: 'user_locations_confirmation',
        /**
         * If the user confirms the deletion
         * Unlink the location from the login
         */
        accept: () => {
          this.unlinkLocation(location);
        },
        reject: () => {
          console.log('The unlink location was cancelled');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

</style>